import { Directive, ElementRef, HostListener } from '@angular/core';
import { ValidationRegex } from '../modules/contact-registry/models/AppConstants';

@Directive({
  selector: '[namePattern]',
})
export class NamePatternDirective {
  alphabatesRegex = new RegExp(ValidationRegex.firstLastNamePattern);

  constructor(private el: ElementRef) {}

  // directive that will allow user to enter [a-z, A-Z], ' , - and space
  @HostListener('keydown', ['$event']) onKeyDown(event: any) {
    const e = <any>event;
    if (e.target.id === 'firstName' || e.target.id === 'lastName') {
      if (
        ([46, 8, 9, 27, 13, 222, 32, 189].indexOf(e.keyCode) !== -1 &&
          e.key !== '_' &&
          e.key !== '"') ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Backspace
        (e.keyCode === 8 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Space
        e.keyCode === 32 ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)
      ) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a alphabet and stop the keypress
      if (e.keyCode < 65 || e.keyCode > 90) {
        e.preventDefault();
      }
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: any) {
    const e = <any>event;
    if (e.target.id === 'alphabet') {
      let val: any;
      navigator['clipboard'].readText().then((data) => {
        val = data;
        if (
          !this.alphabatesRegex.test(val) &&
          event.target.value.indexOf(val) > -1
        ) {
          e.preventDefault();
          const ele = this.el.nativeElement as HTMLInputElement;
          if (!this.alphabatesRegex.test(val)) {
            if (event.target.value.indexOf(val) > -1) {
              ele.value = event.target.value.replace(val, '');
            }
          }
        } else {
          return;
        }
      });
    }
  }
}
