<div class="wizard-container">
  <div class="step-container-wrapper d-flex align-items-center">
    <div
      class="step-item-container d-flex align-items-center"
      [ngClass]="w.step < wizard.length ? 'step-right-line' : ''"
      *ngFor="let w of wizard"
    >
      <div
        class="item-data-container d-flex"
        [ngClass]="w.step === activeStep ? 'activeStep' : ''"
      >
        <div
          class="item-check-container"
          [ngClass]="
            w.step < activeStep
              ? 'greenBg'
              : w.step === activeStep
                ? 'blueBg'
                : 'grayBg'
          "
        >
          <span
            *ngIf="w.step >= activeStep"
            [ngClass]="w.step === activeStep ? 'whiteColor' : 'darkGrayColor'"
            >{{ w.step }}</span
          >
          <img
            *ngIf="w.step < activeStep"
            src="./assets/icons/check_circle-success.svg"
            alt=""
          />
        </div>
        <div
          class="item-text-container"
          [ngClass]="
            activeStep === w.step
              ? 'darkColor'
              : isAllStepValid
                ? 'blueColor'
                : activeStep > w.step
                  ? 'blueColor'
                  : 'darkColor'
          "
          [innerHTML]="w.name"
        ></div>
      </div>
      <div class="line-between-steps" *ngIf="w.step < wizard.length"></div>
    </div>
  </div>
</div>
