import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { appSettings } from './app/appSettings';


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

if (appSettings.disableLogs) {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.error = function (): void { };
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  console.log = function (): void { };
}
