import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  // pipe will truncate the string to the given number of characters and append the ellipsis (…) to the end of the string.
  transform(value: string | null, charCount: number): string | null {
    let truncatedString: string | null = '';
    if (value && value.length > charCount) {
      truncatedString = value.slice(0, charCount) + '…';
    } else {
      truncatedString = value;
    }
    return truncatedString;
  }
}
