<div class="row date-filter-container">
  <div class="col-12">
    <div *ngIf="title" class="filter-box-container fw-700 n-input-group mb-1">
      {{ title }}
    </div>
    <div class="d-inline-block" [ngClass]="{ 'custom-date': isCustomDate }">
      <p-dropdown
        [options]="dateObjects"
        [(ngModel)]="selectedOption"
        optionLabel="name"
        (onChange)="DateFilterImplemented($event)"
        styleClass="drop-down-filter calendar-icon"
        dropdownIcon="drop-down-icon"
        id="dateFilter"
      ></p-dropdown>
    </div>
    <div *ngIf="isCustomDate" class="d-inline-block align-top">
      <p-calendar
        [(ngModel)]="rangeDates"
        selectionMode="range"
        [readonlyInput]="true"
        [showIcon]="true"
        [styleClass]="'custom-height'"
        (ngModelChange)="CustomDateSelectionModelChange()"
        [showButtonBar]="true"
        placeholder="{{ dateRangePlaceholderText }}"
      ></p-calendar>
    </div>
  </div>
</div>
