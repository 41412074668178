<div class="moda-container d-flex justify-content-center align-items-center">
  <div class="modal-area">
    <div class="modal-header d-flex justify-content-between align-items-center">
      <div class="text fw-700">{{ title }}</div>
      <img
        src="./assets/icons/close-white.svg"
        alt=""
        (click)="CloseModalWindow()"
      />
    </div>
    <div class="notes-label fw-700">{{ subTitle }}</div>
    <div>
      <textarea
        class="notes-text-area"
        [(ngModel)]="commentText"
        [maxlength]="maxTextLength"
        (input)="ValidateLength($event)"
        placeholder="Enter comment here"
      ></textarea>
    </div>
    <div class="display-text-count">
      {{ commentText.length }}/{{ maxTextLength }}
    </div>
    <div class="notes-footer">
      <div
        class="m-cat-form-footer d-flex justify-content-end align-items-center"
      >
        <diV class="add-note-div">
          <button-component
            [buttonClass]="'button button-light button-width'"
            Text="Clear"
            [isLeftImg]="false"
            [isRightImg]="false"
            [isButtonDisabled]="IsClearButtonDisabled()"
            (OnClick)="ClearNote()"
          ></button-component>
        </diV>
        <button-component
          [buttonClass]="'button button-dark button-width'"
          Text="Ok"
          [isLeftImg]="false"
          [isRightImg]="false"
          [isButtonDisabled]="false"
          (OnClick)="SaveNote()"
        ></button-component>
      </div>
    </div>
  </div>
</div>
