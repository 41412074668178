import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';
import { ValidationRegex } from '../modules/contact-registry/models/AppConstants';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UtilitiesService {
  isStickyHeader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  constructor(private location: Location) {}

  // remove ovject properties with no value
  async removeEmptyObjElement(obj: any) {
    for (const propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ''
      )
        delete obj[propName];
    }
    return obj;
  }

  // this function will be use to go back to the previous page
  GoBack() {
    this.location.back();
  }

  // validate two given time
  ValidateTime(from: string, to: string) {
    const timeNow: any = new Date();
    const fromTime = from.split(':');
    const fromDateTime = new Date(
      timeNow.getYear(),
      timeNow.getMonth(),
      timeNow.getDate(),
      parseInt(fromTime[0]),
      parseInt(fromTime[1]),
      0,
      0,
    );
    const toTime = to.split(':');
    const toDateTime = new Date(
      timeNow.getYear(),
      timeNow.getMonth(),
      timeNow.getDate(),
      parseInt(toTime[0]),
      parseInt(toTime[1]),
      0,
      0,
    );
    const diff = Math.abs(fromDateTime.getTime() - toDateTime.getTime());
    if (diff > 2 * 60 * 60 * 1000) return true;
    else return false;
  }

  MeridianFormatTime(time: string) {
    // Split the time into hours and minutes
    const timeParts = time.split(':');
    let hours: any = parseInt(timeParts[0]);
    let minutes: any = parseInt(timeParts[1]);

    // Determine if it's AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }
    // Add leading zeros to hours and minutes if necessary
    hours = hours.toString().padStart(2, '0');
    minutes = minutes.toString().padStart(2, '0');
    // Return the formatted time
    return hours + ':' + minutes + ' ' + period;
  }

  FormatTimeTo24Hours(time12h: string | undefined) {
    if (time12h === undefined) {
      return '';
    }

    /* eslint-disable prefer-const */
    let [hours, minutes, modifier] = time12h.split(/\W+/);
    /* eslint-enable prefer-const */

    if (hours === '12') {
      hours = '00';
    }

    if (modifier === 'PM') {
      hours = (parseInt(hours, 10) + 12).toString();
    }

    return `${hours}:${minutes}`;
  }

  // this function will be use to conctinate the address and return address as string
  concatenateAddress(address: any): string {
    return Object.keys(address)?.length
      ? `${address.suite ? address.suite : ''} ${address.streetAddress}, ${
          address.city
        }, ${address.state}, ${address.zipCode}, ${address.country}`.trim()
      : '';
  }

  // custom ReGex pattern validation with reactive forms
  ValidatePattern(control: FormControl): { [s: string]: boolean } | null {
    return new RegExp(ValidationRegex.email).test(control.value)
      ? null
      : { isInvalidEmail: true };
  }

  // Transform telephone number in xxx-xxx-xxxx | xxxxxxxx format
  TransformTelephoneNumber(
    contactNumberPart1: string | null,
    contactNumberPart2: string | null,
    contactNumberPart3: string | null,
    contactNumberExtension?: string | null,
  ): string {
    return `${contactNumberPart1}-${contactNumberPart2}-${contactNumberPart3} ${
      contactNumberExtension ? ' | ' + contactNumberExtension : ''
    }`;
  }
}
