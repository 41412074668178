import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpResponse,
  HttpResponseBase,
} from '@angular/common/http';
import { catchError, mergeMap, Observable, of, throwError } from 'rxjs';
import { CustomHttpClientService } from './custom-http-client.service';
import { FileResponse } from 'src/app/models/FileResponse';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private httpClient: CustomHttpClientService) {}

  public UploadFile<T>(url: string, file: File): Observable<T> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.httpClient.postFile<T>(url, formData);
  }

  public GetFileResponse(url: string): Observable<FileResponse> {
    const options: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      }),
    };

    return this.httpClient
      .request('get', url, options)
      .pipe(
        mergeMap((response: any) => {
          return this.ProcessFileResponse(response);
        }),
      )
      .pipe(
        catchError((response: any) => {
          if (response instanceof HttpResponseBase) {
            try {
              return this.ProcessFileResponse(<any>response);
            } catch (e) {
              return <Observable<FileResponse>>(<any>throwError(e));
            }
          } else return <Observable<FileResponse>>(<any>throwError(response));
        }),
      );
  }

  public GetFileResponseWithInput(
    url: string,
    body: any,
  ): Observable<FileResponse> {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options: any = {
      body: body,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        'Client-TimeZone': timeZone,
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      }),
    };

    return this.httpClient
      .request('post', url, options)
      .pipe(
        mergeMap((response: any) => {
          return this.ProcessFileResponse(response);
        }),
      )
      .pipe(
        catchError((response: any) => {
          if (response instanceof HttpResponseBase) {
            try {
              return this.ProcessFileResponse(<any>response);
            } catch (e) {
              return <Observable<FileResponse>>(<any>throwError(e));
            }
          } else return <Observable<FileResponse>>(<any>throwError(response));
        }),
      );
  }

  private ProcessFileResponse(
    response: HttpResponseBase,
  ): Observable<FileResponse> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse
        ? response.body
        : (<any>response).error instanceof Blob
          ? (<any>response).error
          : undefined;

    const _headers: any = {};
    if (response.headers) {
      for (const key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }

    if (status === 200 || status === 206) {
      const contentDisposition = response.headers
        ? response.headers.get('content-disposition')
        : undefined;
      const fileNameMatch = contentDisposition
        ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition)
        : undefined;
      const fileName =
        fileNameMatch && fileNameMatch.length > 1
          ? fileNameMatch[1]
          : undefined;

      return of({
        fileName: fileName,
        data: responseBlob as any,
        status: status,
        headers: _headers,
      });
    } else if (status !== 200 && status !== 204) {
      return this.BlobToText(responseBlob).pipe(
        mergeMap((_responseText: string) => {
          return throwError(new Error('An unexpected server error occurred.'));
        }),
      );
    }

    return of(null as any);
  }

  private BlobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
      if (!blob) {
        observer.next('');
        observer.complete();
      } else {
        const reader = new FileReader();
        reader.onload = (event) => {
          observer.next((event.target as any).result);
          observer.complete();
        };
        reader.readAsText(blob);
      }
    });
  }
}
