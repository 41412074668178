import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalConfiguration } from 'src/app/models/ModalConfiguration';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input() modalConfiguration: ModalConfiguration = {
    header: '',
    width: 600,
    height: 600,
    includeCancelButton: false,
    cancelButtonText: 'Cancel',
    isCloseable: true,
  } as ModalConfiguration;
  @Output() modalCloseEvent: EventEmitter<void> = new EventEmitter();

  CloseModalWindow() {
    this.modalCloseEvent.emit();
  }
}
