<div class="page-title-container">
  <div
    class="page-title row d-flex justify-content-between align-items-center g-0"
  >
    <div class="page-title-text col fw-400">Registration Summary</div>
    <div
      class="col-6 d-flex justify-content-end align-items-center mr-12"
      *ngIf="showSummary && !userService.isNachaUser()"
    >
      <button class="button button-light" (click)="PrintPdf()">
        <em class="btnPrintIcon"></em>Print
      </button>
      <button class="button button-dark ml-10" (click)="DownloadPdf()">
        <em class="btnDownloadIcon"></em>Download
      </button>
    </div>
  </div>
</div>
<div
  class="row search-container mt-3 d-flex align-items-center rounded-1"
  *ngIf="userService.isNachaAdmin() || userService.isNachaUser()"
>
  <div class="col-8 p-2">
    <search
      (OnSearchData)="OnSearchData($event)"
      (AutoCompleteData)="OnAutoCompleteData($event)"
      [autoCompletePlaceholder]="
        'Search By ODFI or RDFI Name / Primary Routing Number'
      "
      [bindLabel]="'fiName'"
      [bindValue]="'routingNumber'"
      [autoCompleteList]="autoCompleteList"
    ></search>
  </div>
</div>
<div class="row mt-3 summary-container rounded-1" *ngIf="showSummary">
  <div class="col-8 mt-3 ml-5">
    <div class="print-view" #printSummary>
      <div>
        <img
          class="header"
          src="../assets/images/registrationheader.png"
          alt="NACHA"
        />
      </div>
      <br /><br />
      <div class="summary-content" #summaryDetails></div>
      <br /><br /><br />
      <div>
        <div>
          <img
            class="footer"
            src="../assets/images/registrationfooter.png"
            alt="NACHA"
          />
        </div>
        <br />
        <div style="text-align: center">
          <div>{{ addressLine1 }}</div>
          <div>{{ addressLine2 }}</div>
          <div>©{{ year }} {{ addressLine3 }}</div>
        </div>
        <br />
      </div>
    </div>
  </div>
  <div class="mt-3"></div>
</div>
