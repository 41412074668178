import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, lastValueFrom, map } from 'rxjs';
import { CustomHttpClientService } from 'src/app/infrastructure/services/custom-http-client.service';

@Injectable({
  providedIn: 'root',
})
export class PortalRegistrationService {
  portalRegisterMessage: BehaviorSubject<string> = new BehaviorSubject<string>(
    '',
  );

  constructor(private http: CustomHttpClientService) {}

  async GetTimeZone() {
    return lastValueFrom(
      this.http.get(`api/general/timezones`).pipe(map((data: any) => data)),
    );
  }

  async GetEntityTypes() {
    return lastValueFrom(
      this.http.get(`api/general/entitytypes`).pipe(map((data: any) => data)),
    );
  }

  async GetACHOperators() {
    return lastValueFrom(
      this.http.get(`api/general/achoperators`).pipe(map((data: any) => data)),
    );
  }

  async ValidatePrimaryRTN(PrimaryRTN: string) {
    return lastValueFrom(
      this.http
        .get(`api/portal-registration/validate-rtn?rtn=${PrimaryRTN}`)
        .pipe(map((data: any) => data)),
    );
  }

  async ValidateEmailAsync(emailAddress: string) {
    return lastValueFrom(
      this.http
        .get(
          `api/portal-registration/validate-email?emailAddress=${emailAddress}`,
        )
        .pipe(map((data: any) => data)),
    );
  }

  async ValidatePhoneNumberAsync(phoneNumber: string) {
    return lastValueFrom(
      this.http
        .get(`api/portal-registration/phone-number?phoneNumber=${phoneNumber}`)
        .pipe(map((data: any) => data)),
    );
  }

  ValidateTaxID(TaxId: string) {
    return lastValueFrom(
      this.http
        .get(`api/portal-registration/validate-taxid?taxId=${TaxId}`)
        .pipe(map((data: any) => data)),
    );
  }

  async RegisterFI(formData: any) {
    return lastValueFrom(
      this.http
        .post(`api/portal-registration/register-user`, formData)
        .pipe(map((data: any) => data)),
    );
  }
}
