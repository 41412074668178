<div class="accordion" [id]="'accordionExample' + accordianInfo.id">
  <div class="accordion-item">
    <h2 class="accordion-header" [id]="'headingOne' + accordianInfo.id">
      <button
        [ngClass]="
          accordianInfo.collapse
            ? accordianInfo.expandDisabled
              ? 'accordion-button  accordion-disabled collapsed'
              : 'accordion-button collapsed'
            : 'accordion-button'
        "
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="
          accordianInfo.expandDisabled ? '' : '#collapseOne' + accordianInfo.id
        "
        [attr.aria-expanded]="accordianInfo.collapse"
        [attr.aria-controls]="'collapseOne' + accordianInfo.id"
      >
        <div class="w100 h100 d-flex justify-content-start align-items-center">
          <div
            [ngClass]="
              accordianInfo.isHeaderClassRequired
                ? 'accordianheading fw-700'
                : 'accordianheading'
            "
          >
            {{ accordianInfo.name }}
          </div>
          <div *ngIf="isSeperatorRequired" class="accordia-seperator"></div>
          <div class="accsubheading d-flex align-items-center fw-300 h100">
            <img
              *ngIf="
                accordianInfo?.primaryContactList?.length ||
                accordianInfo?.secondaryContactList?.length
              "
              src="./assets/icons/{{
                accordianInfo.isSecondaryDisabled ||
                (accordianInfo.primaryContactList.length &&
                  accordianInfo.secondaryContactList.length)
                  ? 'check_circle-success.svg'
                  : 'info.svg'
              }}"
              alt=""
            />
            <div
              class="d-flex align-items-center h100"
              [innerHTML]="accordianInfo.accordianSubheading"
            ></div>
          </div>
        </div>
      </button>
    </h2>
    <div
      [id]="'collapseOne' + accordianInfo.id"
      [class]="'accordion-collapse collapse ' + accordianInfo.class"
      [attr.aria-labelledby]="'headingOne' + accordianInfo.id"
      [attr.data-bs-parent]="'#accordionExample' + accordianInfo.id"
    >
      <div class="accordion-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
