import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss'],
})
export class AppFooterComponent {
  @Input() showSupportNumber = true;

  currentYear = this.getCurrentYear();
  supportNo = '(703) 349-4556';
  releaseVersion = '3.0.1-3792.0';
  releasedEnvironment = 'UAT';

  getCurrentYear(): number {
    return new Date().getFullYear();
  }
}
