import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { PortalRegistrationService } from '../modules/portal-registration/services/portal-registration.service';

@Directive({
  selector: '[telephoneWithExtension]',
})
export class TelephoneWithExtensionDirective {
  @Output() PhoneNumberStatus = new EventEmitter<boolean>();
  subject: Subject<any> = new Subject();
  subscription = new Subscription();

  constructor(
    public el: ElementRef,
    private portalRegistrationService: PortalRegistrationService,
    private cdr: ChangeDetectorRef,
  ) {}

  // directive that will add (-) at index 4 and 8 && add( | ) at index 12 in phone Number input
  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent) {
    const e = <any>event;
    const val = this.el.nativeElement.value;
    const splitedArr: any[] = val.split('');
    let arrWithoutHyphen: any[] = [];
    if (val.length > 0) {
      if (e.inputType !== 'deleteContentBackward') {
        arrWithoutHyphen = splitedArr.filter((el: string) => {
          return el !== '-';
        });
        if (val.length > 3) {
          arrWithoutHyphen?.splice(3, 0, '-');
        }
        if (val.length > 7) {
          arrWithoutHyphen?.splice(7, 0, '-');
        }
        if (val.length > 12 && !arrWithoutHyphen.includes('|')) {
          arrWithoutHyphen?.splice(12, 0, ' | ');
        }
        this.el.nativeElement.value = arrWithoutHyphen
          .join()
          .replaceAll(',', '');
      }
      this.subject.next('');
    }
  }

  ngOnInit() {
    this.PhoneNumberStatus.emit(true);
    this.subscription.add(
      this.subject.pipe().subscribe(() => {
        const val = this.el.nativeElement.value;
        if (val.length === 12) {
          this.ValidateTelephoneNumber(this.el.nativeElement.value);
        }
      }),
    );
  }

  ValidateTelephoneNumber(phoneNumber: string) {
    const trimmedPhoneNumber = phoneNumber.includes('|')
      ? phoneNumber.split(' | ')[0].replaceAll('-', '')
      : phoneNumber.replaceAll('-', '');
    this.portalRegistrationService
      .ValidatePhoneNumberAsync(trimmedPhoneNumber)
      .then((res: { isValidNumber: boolean }) => {
        this.PhoneNumberStatus.emit(res.isValidNumber);
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
