import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[characterCount]',
})
export class CharacterCountDirective {
  @Input() allowedChars!: number;

  constructor(public el: ElementRef) {}

  // this directive will accept only the number of characters specified in the input

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const e = <any>event;
    const allowedKeysWithoutControl = [
      'Backspace',
      'Delete',
      'ArrowLeft',
      'ArrowRight',
      'Tab',
      'Control',
    ];
    const allowedKeysWithControl = [
      ...allowedKeysWithoutControl,
      'a',
      'c',
      'v',
      'x',
    ];
    if (
      (!e.ctrlKey &&
        !allowedKeysWithoutControl.includes(event.key) &&
        e.target.value.length >= this.allowedChars) ||
      (!allowedKeysWithControl.includes(event.key) &&
        e.target.value.length >= this.allowedChars)
    ) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const e = <any>event;
    const clipboardData: any = event.clipboardData?.getData('text');
    if (clipboardData.length >= this.allowedChars) {
      setTimeout(() => {
        this.el.nativeElement.value = clipboardData.slice(0, this.allowedChars);
      });
    }
  }
}
