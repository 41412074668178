import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Constants } from 'src/app/models/Constants';
import { DateFilter } from 'src/app/models/DateFilter';
import { DatePick } from 'src/app/models/DatePickDto';
import { DateService } from 'src/app/services/date.service';

export const resetDateFilter$ = new Subject<void>();
@Component({
  selector: 'date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit, OnDestroy {
  @Input() title: string | null = null;
  @Output() emitFilterChange = new EventEmitter<DateFilter>();

  private onDestroy$ = new Subject<void>();

  // Output Object
  dateFilter: DateFilter = {} as DateFilter;

  // Others
  isCustomDate = false;
  dateRangePlaceholderText: string = Constants.DateRangePlaceholderText;

  // DatePicker
  rangeDates: Date[] = [];
  dateObjects: DatePick[];
  selectedOption: DatePick;

  constructor(private readonly dateService: DateService) {
    this.dateObjects = this.dateService.GetDateDropdownOptions();
    this.selectedOption = this.dateService.GetDefaultDropdownOption();
  }

  ngOnInit(): void {
    resetDateFilter$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.ResetDateFilter());
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  DateFilterImplemented(event: any): void {
    this.clearDateFilters();
    if (event?.value.id === 5) {
      this.isCustomDate = true;
    } else {
      this.isCustomDate = false;
      this.setDateRange(this.selectedOption);
    }
  }

  CustomDateSelectionModelChange(): void {
    if (this.dateRangeIsValid()) {
      this.setDateRange(this.selectedOption);
    }
  }

  private dateRangeIsValid() {
    // Both dates must be defined/undefined. Undefined is to accomodate a date clearing, which should result in removing the filter.
    return (
      (this.rangeDates?.[0] && this.rangeDates?.[1]) ||
      (!this.rangeDates?.[0] && !this.rangeDates?.[1])
    );
  }

  private ResetDateFilter() {
    this.selectedOption = this.dateObjects.find((d) => d.id === 0)!;
    this.clearDateFilters();
    this.isCustomDate = false;
  }

  private clearDateFilters(): void {
    this.dateFilter.dateFrom = null;
    this.dateFilter.dateTo = null;
    this.rangeDates = [];
  }

  private setDateRange(selectedDateObject: DatePick): void {
    this.dateFilter = this.dateService.ConstructDateFilter(
      selectedDateObject,
      this.rangeDates,
    );
    this.emitFilterChange.emit(this.dateFilter);
  }
}
