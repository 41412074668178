import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[emailPattern]',
})
export class EmailDirective {
  // Email address directive that will only allow the user to enter valid email characters ([a-z], [A-Z], [0-9], [.], [@] and [_])
  constructor(public el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const e = <any>event;
    if (e.target.id === 'email') {
      let isAtExists = false;
      if (event.key === '@') {
        const atIndex = this.el.nativeElement.value.indexOf('@');
        if (atIndex !== -1) {
          isAtExists = true;
        } else {
          isAtExists = false;
        }
      }
      const allowedKeysWithoutControl = [
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'Tab',
        'Control',
      ];
      const allowedKeysWithControl = [
        ...allowedKeysWithoutControl,
        'a',
        'c',
        'v',
        'x',
      ];
      if (isAtExists) {
        event.preventDefault();
      }
      if (
        (!e.ctrlKey &&
          !allowedKeysWithoutControl.includes(event.key) &&
          !this.isValidInput(event.key)) ||
        (!allowedKeysWithControl.includes(event.key) &&
          !this.isValidInput(event.key))
      ) {
        event.preventDefault();
      }
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const e = <any>event;
    if (e.target.id === 'email') {
      const clipboardData: any = event.clipboardData?.getData('text');
      const numberOfAt = clipboardData.split('').filter((el: string) => {
        return el === '@';
      });
      if (numberOfAt.length > 1) {
        event.preventDefault();
      }
      if (!this.isValidInput(clipboardData)) {
        event.preventDefault();
      }
    }
  }

  private isValidInput(input: string | null): boolean {
    if (input === null) {
      return false;
    }
    const pattern = /^[A-Za-z0-9._@+-]+$/;
    return pattern.test(input);
  }
}
