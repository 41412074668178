import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FilterMatchMode } from 'primeng/api';
import { Subject } from 'rxjs';
import { Constants } from 'src/app/models/Constants';
import { Categories, FilterDto, FilterMode } from 'src/app/models/FilterDto';
import { DatePick } from 'src/app/models/DatePickDto';
import { DateService } from 'src/app/services/date.service';

export const clearFilter$ = new Subject();
@Component({
  selector: 'filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit, OnChanges {
  get filterList(): FilterDto[] | null {
    return this._filterList;
  }
  @Input() set filterList(value: FilterDto[] | null) {
    if (value) {
      this.SetFilterList(value);
    }
  }
  @Input() isSearchIconVisible?: boolean = false;
  @Input() isFilterButtonDisabled?: boolean = false;
  @Output() emitFilterData = new EventEmitter<any>();
  _filterList: FilterDto[] = [];
  isFilterActive = false;
  dateRangePlaceholderText: string = Constants.DateRangePlaceholderText;
  totalCount = 0;
  searchedFilters: any[] = [];
  dateObjects: DatePick[];

  constructor(private readonly dateService: DateService) {
    this.dateObjects = this.dateService.GetDateDropdownOptions();
  }

  ngOnChanges(changes: any) {
    if (changes.filterList?.currentValue) {
      this.filterList = changes.filterList?.currentValue;
      let totalFilterItems: Categories[] = [];
      this.filterList
        ?.map((element: FilterDto) => {
          return element?.filters;
        })
        ?.forEach((el: any) => {
          totalFilterItems = totalFilterItems?.concat(el);
        });
      this.totalCount = totalFilterItems?.reduce(
        (n, { count }) => (count ? n + count : n + 0),
        0,
      );
    }
  }

  ngOnInit(): void {
    clearFilter$.subscribe(() => this.ClearFilter());
  }

  SetFilterList(value: FilterDto[] | null) {
    value?.forEach((element: any) => {
      if (
        element.filterMode === FilterMode.primeDropBox ||
        element.filterMode === FilterMode.selectList
      ) {
        element.matchModeValue =
          element.matchModeValue || element.matchModeOptions[0];
      } else if (
        element.filterMode === FilterMode.dateFilter &&
        element.dateTypeOptions
      ) {
        element.selectedDateTypeValue =
          element.selectedDateTypeValue || element.dateTypeOptions[0];
      }
    });
    this._filterList = value || [];
  }

  SetCheckboxDirection(categoryArr: any): any {
    if (categoryArr) {
      return categoryArr.length > 2 ? 'flex-row' : 'flex-column';
    }
  }

  TotalCount(filterType: string | undefined) {
    const categoryList: any = this.filterList?.find((el: any) => {
      return el.filterType === filterType;
    });
    if (filterType) {
      return categoryList.filters
        .map((el: any) => {
          return el.count;
        })
        .reduce((prev: number, next: number) => prev + next);
    }
  }

  CheckFilterArrLength(): any {
    if (this.filterList) {
      return this.filterList.length - 1;
    }
  }

  SelectAllCheckbox(event: any, index: number) {
    if (this.filterList) {
      this.filterList[index].filters?.forEach((el: any) => {
        el.checked = event.target.checked;
      });
      this.filterList[index].allChecked = event.target.checked;
      if (!this.isSearchIconVisible) this.GetSelectedFilterElements();
    }
  }

  SelectFilterCheckbox(
    event: any,
    filterListIndex: number,
    categoryIndex: number,
  ) {
    if (
      this.filterList &&
      this.filterList[filterListIndex]?.filters &&
      this.filterList[filterListIndex]?.filters[categoryIndex]
    ) {
      this.filterList[filterListIndex].filters[categoryIndex].checked =
        event.target.checked;

      const allIndex = this.filterList[filterListIndex].filters.findIndex(
        (el: any) => el.name === 'All',
      );
      if (allIndex >= 0) {
        this.filterList[filterListIndex].filters[allIndex].checked =
          this.filterList[filterListIndex].filters.filter((el: any) => {
            return el.name !== 'All' && el.checked;
          }).length ===
          this.filterList[filterListIndex].filters.length - 1
            ? true
            : false;
      }

      this.filterList[filterListIndex].allChecked =
        this.filterList[filterListIndex].filters.filter((el: any) => {
          return el.checked;
        }).length === this.filterList[filterListIndex].filters.length
          ? true
          : false;
      if (!this.isSearchIconVisible) this.GetSelectedFilterElements();
    }
  }

  GetSelectedFilterElements() {
    this.searchedFilters = [];
    if (this.filterList) {
      this.filterList.forEach((element: any) => {
        if (element.filterMode === FilterMode.primeDropBox) {
          this.searchedFilters.push({
            filterType: element.filterType,
            filterMatchModeValue: element.matchModeValue.value,
            filterValue: element.filterInputValue,
            filterPropertyName: element.filterPropertyName,
            filterMode: element.filterMode,
          });
        } else if (element.filterMode === FilterMode.checkbox) {
          element.filters.forEach((el: any) => {
            if (el.checked) {
              this.searchedFilters.push({
                filterType: element.filterType,
                filterMatchModeValue: FilterMatchMode.CONTAINS,
                filterValue: el.name,
                filterPropertyName: element.filterPropertyName,
                filterMode: element.filterMode,
              });
            }
          });
        } else if (element.filterMode === FilterMode.dateFilter) {
          this.searchedFilters.push({
            filterType: element.filterType,
            filterSelectedDateType: element.selectedDateTypeValue,
            filterDateValue: element.dateRange,
            filterPropertyName: element.filterPropertyName,
            filterMode: element.filterMode,
          });
        } else if (element.filterMode === FilterMode.selectList) {
          this.searchedFilters.push({
            filterType: element.filterType,
            filterValue: element.matchModeValue,
            filterPropertyName: element.filterPropertyName,
            filterMode: element.filterMode,
          });
        } else if (element.filterMode === FilterMode.radio) {
          this.searchedFilters.push({
            filterType: element.filterType,
            filterValue: element.selectedRadioValue,
            filterPropertyName: element.filterPropertyName,
            filterMode: element.filterMode,
          });
        }
      });
      this.emitFilterData.emit(this.searchedFilters);
    }
  }

  onRadioChange(event: Event, index: number, value: any) {
    if (this.filterList) {
      this.filterList[index].selectedRadioValue = value;
      if (!this.isSearchIconVisible) this.GetSelectedFilterElements();
    }
  }

  ClearFilter() {
    if (this.filterList) {
      this.filterList.forEach((element: any) => {
        element.allChecked = true;
        element.filters.forEach((el: any) => (el.checked = true));
        element.filterInputValue = '';
        element.matchModeValue = null;
        element.selectedDateTypeValue = null;
        element.dateRange = null;
        element.selectedRadioValue = 'All';
      });
      this.searchedFilters = [];
      this.SetFilterList(this.filterList);
      if (!this.isSearchIconVisible)
        this.emitFilterData.emit(this.searchedFilters);
    }
  }

  ApplyFilter() {
    this.isFilterActive = false;
    this.GetSelectedFilterElements();
  }

  onDateRangeClick(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }
}
