import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  SimpleChanges,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
export type paginationConfigDto = {
  activePageSize: string | number;
  pageNumber: number;
};
export const resetPagination$ = new Subject();
@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent {
  readonly pageSizeList: (number | string)[] = [10, 20, 50, 100];
  paginationConfig: paginationConfigDto = {
    activePageSize: 10,
    pageNumber: 1,
  };

  @Input() paginationTitle!: string;
  @Input() pageFiltered!: boolean;
  @Input() totalPageNumber!: number;
  @Input() totalRecords!: number;
  @Output() emitPageConfig = new EventEmitter<paginationConfigDto>();
  @Output() emitClearFilterAction = new EventEmitter<any>();
  rangeStart = 1;
  rangeEnd = 10;
  isGotoDisabled = true;
  filterTitle = 'Filtered';
  Subscription: Subscription = new Subscription();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: any) {
    if (changes.totalPageNumber !== undefined) {
      this.totalPageNumber = changes.totalPageNumber?.currentValue;
    }
    this.cdr.detectChanges();
    this.CalculateRecordsInRange();
  }

  ngOnInit(): void {
    this.Subscription.add(
      resetPagination$.subscribe(() => this.ResetPagination()),
    );
  }

  HasRecords(): boolean {
    return this.totalRecords > 0;
  }

  ResetPagination() {
    this.paginationConfig.pageNumber = 1;
  }

  SetPageSize(event: any): void {
    this.isGotoDisabled = true;
    this.paginationConfig.pageNumber = 1;
    this.paginationConfig.activePageSize = event.target.value;
    this.emitPageConfig.emit(this.paginationConfig);
  }

  CalculateRecordsInRange(): void {
    const startIndex =
      (this.paginationConfig.pageNumber - 1) *
      Number(this.paginationConfig.activePageSize);
    const endIndex = startIndex + Number(this.paginationConfig.activePageSize);
    this.rangeStart = startIndex + 1;
    this.rangeEnd = endIndex;
    if (this.paginationConfig.pageNumber === this.totalPageNumber) {
      this.rangeEnd = this.totalRecords;
    }
    this.cdr.detectChanges();
  }

  GoToPage() {
    if (this.paginationConfig.pageNumber === 1) {
      this.isGotoDisabled = true;
    }
    this.CalculateRecordsInRange();
    this.emitPageConfig.emit(this.paginationConfig);
  }

  OnPageChange(pageChangeType: string): void {
    if (pageChangeType === 'prev') {
      if (this.paginationConfig.pageNumber > 1)
        this.paginationConfig.pageNumber = this.paginationConfig.pageNumber - 1;
      else return;
    } else {
      if (this.paginationConfig.pageNumber < this.totalPageNumber)
        this.paginationConfig.pageNumber = this.paginationConfig.pageNumber + 1;
      else return;
    }
    if (this.paginationConfig.pageNumber === 1) {
      this.isGotoDisabled = true;
    }
    this.CalculateRecordsInRange();
    this.emitPageConfig.emit(this.paginationConfig);
  }

  OnPageInput(event: any) {
    if (event.target.value && event.target.value <= 0) {
      this.isGotoDisabled = true;
    } else if (
      event.target.value &&
      event.target.value <= this.totalPageNumber
    ) {
      this.paginationConfig.pageNumber = +event.target.value;
      this.isGotoDisabled = false;
    } else {
      this.isGotoDisabled = true;
      return;
    }
  }

  ClearFilter() {
    this.emitClearFilterAction.emit();
  }

  ngOnDestroy() {
    this.Subscription.unsubscribe();
  }
}
