import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'splitDateTime',
})
export class SplitDateTimePipe implements PipeTransform {
  transform(value: string): string {
    const splitedDateTime = value.split(', ');
    const date = splitedDateTime[0];
    const time = splitedDateTime[1];
    const finalVal = `<span>${date}</span> <span class="grid-time-wrap">${time}</span>`;
    return finalVal;
  }
}
