import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[dunNumber]',
})
export class DunNumberDirective {
  constructor(public el: ElementRef) {}

  // directive that will add (-) at index 2 and 7 in Dun Number input
  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent) {
    const e = <any>event;
    const val = this.el.nativeElement.value;
    const splitedArr: any[] = val.split('');
    let arrWithoutHyphen: any[] = [];
    if (val.length > 0 && e.inputType !== 'deleteContentBackward') {
      arrWithoutHyphen = splitedArr.filter((el: string) => {
        return el !== '-';
      });
      if (val.length > 2) {
        arrWithoutHyphen?.splice(2, 0, '-');
      }
      if (val.length > 6) {
        arrWithoutHyphen?.splice(6, 0, '-');
      }
      this.el.nativeElement.value = arrWithoutHyphen.join().replaceAll(',', '');
    }
  }
}
