import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentsComponent {
  @Input() isModalOpen = false;
  @Input() title = 'Comment';
  @Input() subTitle = 'Comment';
  @Input() maxTextLength = 250;
  @Output() emitSave = new EventEmitter<string>();
  @Output() emitCancel = new EventEmitter<boolean>();
  commentText = '';

  async ClearNote() {
    this.commentText = '';
  }

  async SaveNote() {
    this.emitSave.emit(this.commentText);
  }

  ValidateLength(event: any): boolean {
    const val = event.target.value;
    return val.length <= this.maxTextLength;
  }

  CloseModalWindow() {
    this.emitCancel.emit(true);
  }

  IsClearButtonDisabled() {
    return this.commentText.length === 0;
  }
}
