<section class="schedule-maintenance-container d-flex justify-content-center">
  <div class="maintenance-box d-flex align-items-start">
    <img src="./assets/icons/construction.svg" alt="" />
    <div class="d-flex flex-column">
      <div class="cons-heading fw-700">Scheduled Maintenance !</div>
      <div class="maintenance-text">
        Due to schedule maintenance activity Risk Management Portal will not be
        available on 8th Jan ‘23 from 1:00 AM to 6:00 PM. Regret inconvenience
        caused.
      </div>
    </div>
  </div>
</section>
