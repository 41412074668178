import { Injectable } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import {
  filter,
  takeUntil,
  Subject,
  BehaviorSubject,
  Observable,
  tap,
  from,
  firstValueFrom,
} from 'rxjs';
import { Router } from '@angular/router';
import { TokenStorageService } from './token-storage.service';
import { UserService } from 'src/app/services/user.service';
import { UserDto } from 'src/app/models/UserDto';
import { CustomHttpClientService } from '../services/custom-http-client.service';
import { GeneralService } from 'src/app/services/general.service';
import { MsalConfigDto } from 'src/app/models/MsalConfigDto';
import * as moment from 'moment';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private isLoggedInSubject = new BehaviorSubject<boolean>(false);
  isLoggedIn$: Observable<boolean> = this.isLoggedInSubject.asObservable();
  private readonly destroy$ = new Subject<void>();
  private msalConfig: MsalConfigDto = {} as MsalConfigDto;

  private readonly LoginPolicy = 'B2C_1A_SIGNUP_SIGNIN';
  private readonly MFALoginPolicy = 'B2C_1A_SIGNIN_PHONEOREMAILMFA';

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private httpService: CustomHttpClientService,
    private tokenService: TokenStorageService,
    private router: Router,
    private userService: UserService,
    private generalService: GeneralService,
  ) {
    this.generalService.msalConfigs$.subscribe((data) => {
      this.msalConfig = data;
    });
  }

  Initialize() {
    this.authService.initialize();
    this.InitLogin();
    this.authService.handleRedirectObservable();
    this.userService.user$.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.isLoggedInSubject.next(true);
      }
    });
  }

  Login() {
    this.tokenService.ClearSession();
    this.authService.loginRedirect();
  }

  ExternalLogout() {
    this.authService.logoutRedirect({
      idTokenHint: this.tokenService.GetToken()!,
      postLogoutRedirectUri: window.location.origin + '/login',
      onRedirectNavigate: (url: string) => {
        this.tokenService.ClearSession();
      },
    });
    this.ShowLogoutInProgress();
  }

  ReAuthenticate(email: string): Observable<any> {
    const request = {
      authority: this.msalConfig.authority
        .replace(this.LoginPolicy, this.msalConfig.secureExchangePolicy)
        .replace(this.MFALoginPolicy, this.msalConfig.secureExchangePolicy),
      scopes: ['openid'],
      loginHint: email,
      prompt: 'login',
    };

    return from(this.authService.loginPopup(request)).pipe(
      tap((response) => {
        this.SaveClaimAndRedirect(response.idToken, 'secure-exchange/document');
      }),
    );
  }

  ShowLogoutInProgress() {
    this.isLoggedInSubject.next(false);
    this.router.navigate(['/log-out']);
  }

  NavigateToLogin() {
    this.isLoggedInSubject.next(false);
    this.router.navigate(['/login']);
  }

  private InitLogin() {
    const loginSuccess$ = this.msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      takeUntil(this.destroy$),
    );

    const loginFailure$ = this.msalBroadcastService.msalSubject$.pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
      takeUntil(this.destroy$),
    );

    loginFailure$.subscribe((result: EventMessage) => {
      if (this.tokenService.GetToken()) {
        this.ExternalLogout();
      } else {
        this.NavigateToLogin();
      }
    });

    const inProgress$ = this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      takeUntil(this.destroy$),
    );

    loginSuccess$.subscribe((result: EventMessage) => {
      const payload = result.payload as AuthenticationResult;
      this.authService.instance.setActiveAccount(payload.account);
      this.SaveClaimAndRedirect(payload.idToken, 'dashboard');
      this.isLoggedInSubject.next(true);
    });

    inProgress$.subscribe(() => {
      const activeAccount = this.authService.instance.getActiveAccount();
      if (activeAccount) {
        const isExpired = this.CheckTokenExpiration(
          activeAccount.idTokenClaims,
        );
        if (isExpired) {
          this.RefreshToken(window.location.pathname);
        } else {
          this.SaveClaimAndRedirect(activeAccount.idToken!, this.GetRoute());
        }
      }
    });
  }

  // Login action not required for B2C. B2C will automatically redirect to the login page with the token
  private GetRoute(): string {
    const b2cReservedRoutes = ['/login', '/redirect'];
    if (b2cReservedRoutes.includes(window.location.pathname)) {
      return 'dashboard';
    }
    return window.location.pathname;
  }

  private GetUserClaims(): Promise<UserDto> {
    return firstValueFrom(
      this.httpService.get<UserDto>(`api/auth/get-user-claims`),
    );
  }

  private CheckTokenExpiration(idTokenClaims: any): boolean {
    const expTimestamp = idTokenClaims?.exp ?? 0;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return expTimestamp < currentTimestamp;
  }

  private SaveClaimAndRedirect(token: string, redirectUri: string) {
    this.tokenService.SaveToken(token);
    this.GetUserClaims().then(
      (data: UserDto) => {
        this.tokenService.SaveUserToStorage(data);
        if (redirectUri) {
          this.router.navigate([redirectUri]);
        }
      },
      (error) => {
        this.ExternalLogout();
      },
    );
  }

  RefreshToken(redirectUri: string): Observable<any> {
    return from(
      this.authService.instance.ssoSilent({ scopes: ['openid'] }),
    ).pipe(
      tap((response) =>
        this.SaveClaimAndRedirect(response.idToken, redirectUri),
      ),
    );
  }

  RefreshSecureExchangeToken(): Observable<any> {
    if (this.msalConfig.secureExchangeSessionTimeout) {
      const sessionTimeout = this.msalConfig.secureExchangeSessionTimeout;
      const unixLoginTime = this.tokenService.GetClaimValueByKey('Logintime');
      // Refresh token 5 minutes before it expires
      const minutesAgo = moment()
        .utc()
        .subtract(sessionTimeout - 5, 'minutes')
        .unix();
      if (parseInt(unixLoginTime) <= minutesAgo) {
        return from(
          this.authService.instance.ssoSilent({
            scopes: ['openid'],
            authority: this.msalConfig.authority
              .replace(this.LoginPolicy, this.msalConfig.secureExchangePolicy)
              .replace(
                this.MFALoginPolicy,
                this.msalConfig.secureExchangePolicy,
              ),
            account: this.authService.instance.getActiveAccount()!,
          }),
        ).pipe(
          tap((response) => this.SaveClaimAndRedirect(response.idToken, '')),
        );
      }
    }

    return new Observable((observer) => {
      observer.next();
      observer.complete();
    });
  }
}
