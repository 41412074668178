import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

@Component({
  selector: 'alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss'],
})
export class AlertMessageComponent implements OnChanges {
  @Input() messageText = '';
  @Input() icon = '';
  @Input() background = '';
  @Output() closeAlert = new EventEmitter();

  ngOnChanges(changes: any) {
    this.messageText = changes.messageText?.currentValue;
    setTimeout(() => {
      this.closeAlert.emit();
    }, 5000);
  }
}
