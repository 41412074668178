import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[phoneNumber]',
})
export class PhoneNumberDirective {
  // Directive that will accept only numbers, hyphen(-), space( ) and pipe (|) for phone number

  constructor(public el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const e = <any>event;
    if (e.target.id === 'TelephoneNumber') {
      const allowedKeysWithoutControl = [
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'Tab',
        'Control',
      ];
      const allowedKeysWithControl = [
        ...allowedKeysWithoutControl,
        'a',
        'c',
        'v',
        'x',
      ];
      if (
        (!e.ctrlKey &&
          !allowedKeysWithoutControl.includes(event.key) &&
          !this.isValidInput(event.key)) ||
        (!allowedKeysWithControl.includes(event.key) &&
          !this.isValidInput(event.key))
      ) {
        event.preventDefault();
      } else {
        const e = <any>event;
        const val = this.el.nativeElement.value;
        const splitedArr: any[] = val.split('');
        let arrWithoutHyphen: any[] = [];
        if (val.length > 0 && e.key !== 'Backspace') {
          arrWithoutHyphen = splitedArr.filter((el: string) => {
            return el !== '-';
          });
          if (val.length > 2) {
            arrWithoutHyphen?.splice(3, 0, '-');
          }
          if (val.length > 6) {
            arrWithoutHyphen?.splice(7, 0, '-');
          }
          if (val.length > 11 && val.indexOf('|') === -1) {
            arrWithoutHyphen = arrWithoutHyphen
              .join()
              .replaceAll(' ', '')
              .replaceAll(',', '')
              .split('');
            arrWithoutHyphen?.splice(12, 0, ' | ');
          }
          this.el.nativeElement.value = arrWithoutHyphen
            .join()
            .replaceAll(',', '');
          if (
            this.el.nativeElement.value.indexOf(' |') !== -1 &&
            this.el.nativeElement.value.indexOf(' | ') === -1
          ) {
            this.el.nativeElement.value =
              this.el.nativeElement.value.replaceAll(' |', ' | ');
          }
        }
      }
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const e = <any>event;
    if (e.target.id === 'TelephoneNumber') {
      const clipboardData: any = event.clipboardData?.getData('text');
      if (!this.isValidInput(clipboardData)) {
        event.preventDefault();
      } else {
        const splitedArr: any[] = clipboardData.split('');
        let arrWithoutHyphen: any[] = [];
        arrWithoutHyphen = splitedArr.filter((el: string) => {
          return el !== '-';
        });
        if (arrWithoutHyphen[3] !== '-') {
          arrWithoutHyphen?.splice(3, 0, '-');
        }
        if (arrWithoutHyphen[7] !== '-') {
          arrWithoutHyphen?.splice(7, 0, '-');
        }
        setTimeout(() => {
          if (clipboardData.length > 10) {
            arrWithoutHyphen?.splice(12, 0, ' | ');
          }
          this.el.nativeElement.value = arrWithoutHyphen
            .join()
            .replaceAll(',', '');
        }, 100);
      }
    }
  }

  private isValidInput(input: string | null): boolean {
    if (input === null) {
      return false;
    }
    const pattern = /^[0-9 \-|]*$/;
    return pattern.test(input);
  }
}
