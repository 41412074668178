import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeformat',
})
export class TimeformatPipe implements PipeTransform {
  transform(value: any): any {
    if (value && value !== 'NA') {
      const timeArr = value.split(' - ');
      const fromDate = new Date('01/01/2023 ' + timeArr[0]);
      const toDate = new Date(
        '01/01/2023 ' +
          timeArr[1].split(' ')[0] +
          ' ' +
          timeArr[1].split(' ')[1],
      );
      const timeZoneArr = value.split(' ');
      const time =
        fromDate.getHours() +
        ':' +
        fromDate.getMinutes() +
        ' - ' +
        toDate.getHours() +
        ':' +
        toDate.getMinutes() +
        ' ' +
        timeZoneArr[timeZoneArr.length - 1];
      const fromHour =
        Number(time.split(' - ')[0].split(':')[0]) < 10
          ? '0' + time.split(' - ')[0].split(':')[0]
          : time.split(' - ')[0].split(':')[0];
      const fromMinut =
        Number(time.split(' - ')[0].split(':')[1]) < 10
          ? '0' + time.split(' - ')[0].split(':')[1]
          : time.split(' - ')[0].split(':')[1];
      const toSHour =
        Number(time.split(' - ')[1].split(':')[0]) < 10
          ? '0' + time.split(' - ')[1].split(':')[0]
          : time.split(' - ')[1].split(':')[0];
      const toMinut =
        Number(time.split(' - ')[1].split(':')[1].split(' ')[0]) < 10
          ? '0' + time.split(' - ')[1].split(':')[1].split(' ')[0]
          : time.split(' - ')[1].split(':')[1].split(' ')[0];
      const finalTime =
        fromHour +
        ':' +
        fromMinut +
        ' - ' +
        toSHour +
        ':' +
        toMinut +
        ' ' +
        timeZoneArr[timeZoneArr.length - 1];
      return finalTime;
    } else {
      return 'NA';
    }
  }
}
