import { DomService } from './../infrastructure/services/dom.service';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { FileService } from 'src/app/infrastructure/services/file.service';
import { FileResponse } from 'src/app/models/FileResponse';

@Injectable({
  providedIn: 'root',
})
export class OptInProgramService {
  constructor(
    private fileService: FileService,
    private domService: DomService,
  ) {}

  async DownloadFile() {
    this.GetFile().then((data) => {
      if (data != null) {
        const fileName: string = data.fileName ?? 'Opt-inDocument.pdf';
        this.domService.saveOrOpenFile(fileName, data.data);
      }
    });
  }

  async GetFile(): Promise<FileResponse> {
    const url = `api//opt-in-program/opt-in-document-file`;
    return lastValueFrom(this.fileService.GetFileResponse(url));
  }

  async UploadFile(file: File): Promise<string> {
    const url = `api//opt-in-program/opt-in-document-file`;
    return lastValueFrom(this.fileService.UploadFile<string>(url, file));
  }
}
