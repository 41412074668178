<div class="page-title-container">
  <div
    class="page-title row g-0 d-flex justify-content-between align-items-center"
  >
    <div class="page-title-text col fw-400">{{ pageTitle }}</div>
    <div
      *ngIf="createButtonVisible || exportButtonVisible"
      class="col d-flex justify-content-end align-items-center me-3"
    >
      <button-component
        *ngIf="createButtonVisible"
        [buttonClass]="'button button-dark'"
        [isLeftImg]="false"
        [isRightImg]="false"
        [Text]="createButtonLabel"
        [isButtonDisabled]="false"
        (OnClick)="OnClickCreateButtonAction()"
      ></button-component>
      <button-component
        *ngIf="exportButtonVisible && exportItems.length === 1"
        class="ms-2"
        [buttonClass]="'button button-dark'"
        [isLeftImg]="true"
        [isRightImg]="false"
        [Text]="'Export'"
        [buttonImage]="'share_windows.svg'"
        [isButtonDisabled]="!(exportDataLength > 0)"
        (OnClick)="OnClickExportButtonAction(defaultExportKey)"
      ></button-component>
      <p-splitButton
        *ngIf="exportButtonVisible && exportItems.length > 1"
        label="Export"
        icon="export-icon"
        iconPos="left"
        [model]="exportItems"
        (onClick)="OnClickExportButtonAction(defaultExportKey)"
        styleClass="button button-dark ml-10"
        [disabled]="!(exportDataLength > 0)"
      >
      </p-splitButton>
    </div>
  </div>
  <ng-content></ng-content>
</div>
