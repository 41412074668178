import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { RegExPatterns } from '../models/RegExPatterns';

@Directive({
  selector: '[numberInputRestriction]',
})
export class NumberInputRestrictionDirective {
  @Input() maxDigits = 18;
  @Input() maxDecimalPlaces = 0;

  constructor(
    private el: ElementRef,
    private ngControl: NgControl,
  ) {}

  @HostListener('input', ['$event']) onInput(): void {
    const inputElement = this.el.nativeElement as HTMLInputElement;
    let inputValue = inputElement.value.replace(
      RegExPatterns.NonDigitOrDecimal,
      '',
    );
    let [wholeNumbers, decimalPlaces] = inputValue.split('.');
    const hasDecimal = inputValue.indexOf('.') !== -1;

    if (decimalPlaces) {
      decimalPlaces = decimalPlaces.substring(0, this.maxDecimalPlaces);
    }

    if (wholeNumbers) {
      wholeNumbers = wholeNumbers.substring(
        0,
        this.maxDigits - (decimalPlaces ? decimalPlaces.length : 0),
      );
    }

    inputValue =
      decimalPlaces || hasDecimal
        ? `${wholeNumbers}.${decimalPlaces}`
        : wholeNumbers;

    this.ngControl.control!.setValue(inputValue, { emitEvent: false });
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void {
    const currentValue: string = this.ngControl.control?.value || '';

    if (
      event.key === '.' &&
      (currentValue.includes('.') || this.maxDecimalPlaces === 0)
    ) {
      event.preventDefault();
    }
  }
}
