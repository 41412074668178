<div class="container-fluid footerSticky">
  <div class="d-flex align-items-center footerSocial">
    <div class="flex-fill d-flex justify-content-start align-items-center">
      <span class="footerTextSize pe-2"
        >Return to
        <a href="https://www.nacha.org/" target="_blank">Nacha</a></span
      >
      <div class="vr vrcommon"></div>
      <a
        class="footerSocialImage"
        href="https://www.facebook.com/NachaOnline/"
        target="_blank"
        ><img src="assets\icons\ic_baseline-facebook.svg"
      /></a>
      <a
        class="footerSocialImage"
        href="https://www.youtube.com/c/nacha"
        target="_blank"
        ><img src="assets\icons\mdi_youtube.svg"
      /></a>
      <a
        class="footerSocialImage"
        href="https://twitter.com/NACHAOnline"
        target="_blank"
        ><img src="assets\icons\x.svg"
      /></a>
      <a
        class="footerSocialImage"
        href="https://www.linkedin.com/company/nacha"
        target="_blank"
        ><img src="assets\icons\uil_linkedin.svg"
      /></a>
    </div>
    <div class="flex-fill supportInfo d-flex justify-content-end">
      <div *ngIf="showSupportNumber" class="align-items-center d-flex">
        <span class="footerStyle footerTextSize fw-700 pe-2">Support:</span>
        <span id="support" class="footerStyle footerTextSize fw-400 pe-2">{{
          supportNo
        }}</span>
      </div>
      <div class="d-flex flex-column releaseNotes justify-content-center">
        <div class="d-flex flex-row">
          <a
            href="https://www.nacha.org/content/legal-information"
            target="_blank"
            class="ps-2 footerStyle footerNotes footerTextSize fw-400 text-decoration-underline"
            >Legal</a
          >
          <div class="vr vrReleaseNote"></div>
          <span
            id="currentYear"
            class="footerStyle footerNotes footerTextSize fw-400 pe-2"
            >&#169; {{ currentYear }} Nacha</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
