<div
  class="dropdown"
  [class.show]="isDropdownOpen"
  (click)="toggleDropdown()"
  (clickOutside)="isDropdownOpen = false"
>
  <button
    class="dropdown-toggle"
    type="button"
    id="dropdownMenuButton"
    [class.active]="isDropdownOpen"
  >
    <img [src]="'./assets/icons/' + icon" alt="" />
  </button>
  <div
    class="dropdown-menu"
    aria-labelledby="dropdownMenuButton"
    [ngClass]="
      menuAlignment === 'left' && isDropdownOpen
        ? 'dropdown-menu-show-left'
        : isDropdownOpen
          ? 'dropdown-menu-show-right'
          : ''
    "
  >
    <a
      *ngFor="let item of contextMenuItems"
      class="dropdown-item"
      href="#"
      (click)="item.action($event)"
    >
      <img class="dropdown-icon" [src]="item.icon" [alt]="item.title" />
      {{ item.label }}
    </a>
  </div>
</div>
