import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities.service';

@Component({
  selector: 'time-picker-component',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {
  @Input() public Id = '';
  @Input() public Label = '';
  @Input() public Name = '';
  @Input() public IsSubmitted = false;
  @Input() public FormGroup: UntypedFormGroup = new FormGroup({});
  @Input() public IsRequired = false;
  @Input() private MinuteInterval = 30;
  @Input() private MinTime = '00:00';
  @Input() private MaxTime = '23:59';

  public timeString: any = [];

  constructor(private utils: UtilitiesService) {}

  public ngOnInit(): void {
    this.timeString = this.GenTimePullDown(
      this.MinuteInterval,
      this.MinTime,
      this.MaxTime,
    );
  }

  private GenTimePullDown(
    minuteInterval: number,
    minTime: string,
    maxTime: string,
  ) {
    let minutes = 0,
      hours = 0;
    const timeOptions = [
      { value: minTime, label: this.utils.MeridianFormatTime(minTime) },
    ];
    const maxHour = parseInt(maxTime.split(':')[0]);
    const maxMinute = parseInt(maxTime.split(':')[1]);
    const step = (60 / minuteInterval) * 24;

    for (let e = 1; e < step; e++) {
      minutes += minuteInterval;

      if (minutes == 60) {
        hours += 1;
        minutes = 0;
      }

      if (hours == maxHour && minutes > maxMinute) {
        break;
      }

      const time =
        hours.toString().padStart(2, '0') +
        ':' +
        minutes.toString().padStart(2, '0');
      timeOptions.push({
        value: time,
        label: this.utils.MeridianFormatTime(time),
      });
    }

    return timeOptions;
  }
}
