<button
  class="d-flex"
  [class]="buttonClass"
  type="button"
  [id]="Id"
  [ngClass]="isLeftImg ? 'flex-row' : 'flex-row-reverse'"
  [ngClass]="isButtonDisabled ? 'button button-gray' : ''"
  [disabled]="isButtonDisabled"
  (click)="ClickEvent()"
>
  <img
    [src]="'./assets/icons/' + buttonImage"
    alt=""
    *ngIf="isLeftImg || isRightImg"
  />
  <span
    [ngClass]="{ 'btnicon-left': isLeftImg, 'btnicon-right': isRightImg }"
    >{{ Text }}</span
  >
</button>
