import { Injectable } from '@angular/core';
import { CustomHttpClientService } from '../infrastructure/services/custom-http-client.service';
import { BehaviorSubject, lastValueFrom, map } from 'rxjs';
import { InstructionDto } from '../modules/contact-registry/models/InstructionDto';
import { ErrorMessageDto } from '../models/ErrorMessageDto';
import { SelectListItem } from '../modules/shared/models/SelectListItems';
import { StateDto } from '../modules/shared/models/StateDto';
import { FAQSectionDto } from '../modules/faq/models/FAQSectionDto';
import { FileService } from '../infrastructure/services/file.service';
import { FileResponse } from '../models/FileResponse';
import { ConditionalMenuDto } from '../models/conditionalMenuDto';
import { AlertEvent } from '../modules/common-components/alert/models/alert-event';
import { MsalConfigDto } from '../models/MsalConfigDto';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  errorMessageArr: ErrorMessageDto[] = [];
  conditionalMenuItems: BehaviorSubject<ConditionalMenuDto> =
    new BehaviorSubject({} as ConditionalMenuDto);
  msalConfigs$: BehaviorSubject<MsalConfigDto> = new BehaviorSubject(
    {} as MsalConfigDto,
  );

  constructor(
    private readonly http: CustomHttpClientService,
    private readonly fileService: FileService,
  ) {}

  UpdateTermsOfUseAccepted() {
    return lastValueFrom(this.http.post('api/general/accept-terms', {}, false));
  }

  async GetFAQsAsync(): Promise<FAQSectionDto[]> {
    return lastValueFrom(this.http.get<FAQSectionDto[]>('api/general/faqs'));
  }

  GetTermsOfUserPdf(): Promise<FileResponse> {
    return lastValueFrom(
      this.fileService.GetFileResponse('/api/general/pdf/terms-of-use'),
    );
  }

  async GetAchOperatorsAsync(): Promise<SelectListItem[]> {
    return lastValueFrom(
      this.http
        .get<SelectListItem[]>('api/general/achoperators')
        .pipe(map((data: SelectListItem[]) => data)),
    );
  }

  async GetEntityTypesAsync(): Promise<SelectListItem[]> {
    return lastValueFrom(
      this.http
        .get<SelectListItem[]>('api/general/entitytypes')
        .pipe(map((data: SelectListItem[]) => data)),
    );
  }

  async GetUSAStateCodesAsync(): Promise<StateDto[]> {
    return lastValueFrom(
      this.http
        .get<StateDto[]>('api/general/usa-states')
        .pipe(map((data: StateDto[]) => data)),
    );
  }

  async GetTextsAsync(keys: string): Promise<any[]> {
    return lastValueFrom(
      this.http
        .get<any[]>(`api/general/texts?keys=${keys}`)
        .pipe(map((data: any[]) => data)),
    );
  }

  async GetConfigsAsync(keys: string): Promise<any[]> {
    return lastValueFrom(
      this.http
        .get<any[]>(`api/general/configs?keys=${keys}`)
        .pipe(map((data: any[]) => data)),
    );
  }

  async GetInstructionsByModule(
    moduleName: string | null,
  ): Promise<InstructionDto> {
    return lastValueFrom(
      this.http
        .get<InstructionDto>(`api/instructions/upload/${moduleName}`)
        .pipe(map((data) => data)),
    );
  }
  async GetTimeZone() {
    return lastValueFrom(
      this.http.get(`api/general/timezones`).pipe(map((data: any) => data)),
    );
  }

  async GetErrorAsync(): Promise<ErrorMessageDto[]> {
    return lastValueFrom(
      this.http
        .get<ErrorMessageDto[]>(`/api/general/errormessages`)
        .pipe(map((data: ErrorMessageDto[]) => data)),
    );
  }

  async GetTimeoutSessionValue(): Promise<number> {
    return lastValueFrom(
      this.http
        .get<number>(`/api/general/get-session-timeout-value`)
        .pipe(map((data: number) => data)),
    );
  }

  GetAllMessage() {
    this.GetErrorAsync().then((data: ErrorMessageDto[]) => {
      this.errorMessageArr = data;
    });
  }

  GetErrorMessage(key: string) {
    const err = this.errorMessageArr?.find(
      (x: ErrorMessageDto) => x.errorMessageKey === key,
    );
    return err ? err.errorMessageString : '';
  }

  getConditionalMenu() {
    return lastValueFrom(
      this.http
        .get<ConditionalMenuDto>(`/api/fi/sidebar`)
        .pipe(map((data: ConditionalMenuDto) => data)),
    );
  }

  GetCancelAlert() {
    const title = this.GetErrorMessage('VerbiageCancelTitle');
    const msg = this.GetErrorMessage('VerbiageCancelMessage');
    const alertEvent: AlertEvent = {
      title: title,
      message: msg,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true,
    };
    return alertEvent;
  }

  GetMsalConfigs() {
    return lastValueFrom(
      this.http
        .get<any>(`/api/config-api/msal-configs`)
        .pipe(map((data: MsalConfigDto) => this.msalConfigs$.next(data))),
    );
  }
}
