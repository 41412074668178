import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import * as moment from 'moment';
import { EventTypes } from '../toast/models/event-types';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'message-panel',
  templateUrl: './message-panel.component.html',
  styleUrls: ['./message-panel.component.scss'],
})
export class MessagePanelComponent {
  @Input() closeIconVisible?: boolean = false;
  @Input() downloadIconVisible?: boolean = false;
  @Input() messageIconType: EventTypes = EventTypes.Success;
  @Input() panelmessages: string[] = [];
  @Input() panelmessagesPartial = '';
  @Input() showAllMessages = false;
  @Input() selectedFileName = '';
  @Output() disposeEvent = new EventEmitter();

  fileName = '';
  errorDownloadLink = '';
  errorIconDisplay = false;
  successIconDisplay = false;
  warningIconDisplay = false;
  infoIconDisplay = false;
  messageContainerClass = '';
  currentMessageCount = 0;
  totalMessageCount = 0;
  routeSubscription!: Subscription;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {}

  ngOnChanges(changes: any) {
    this.panelmessages = [];
    this.panelmessages = changes.panelmessages?.currentValue;
    this.panelmessages = [...new Set(this.panelmessages)];
    this.selectedFileName = changes.selectedFileName?.currentValue
      ? changes.selectedFileName?.currentValue
      : this.selectedFileName;
    this.createFileName();
    this.setIconType();
    this.currentMessageCount = !this.showAllMessages ? 1 : 0;
    this.totalMessageCount = this.panelmessages.length;
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.disposeEvent.emit();
      }
    });
    this.createFileName();
    this.setIconType();
    this.currentMessageCount = !this.showAllMessages ? 1 : 0;
    this.totalMessageCount = this.panelmessages.length;
    this.cdr.detectChanges();
  }

  setIconType() {
    this.successIconDisplay = false;
    this.errorIconDisplay = false;
    this.warningIconDisplay = false;
    this.infoIconDisplay = false;

    switch (this.messageIconType) {
      case EventTypes.Success:
        this.successIconDisplay = true;
        this.messageContainerClass = 'msg-container-success';
        break;
      case EventTypes.Info:
        this.infoIconDisplay = true;
        this.messageContainerClass = 'msg-container-info';
        break;
      case EventTypes.Warning:
        this.warningIconDisplay = true;
        this.messageContainerClass = 'msg-container-warning';
        break;
      case EventTypes.Error:
        this.errorIconDisplay = true;
        this.messageContainerClass = 'msg-container-failure';
        break;
    }
  }

  createFileName() {
    let flName: string;
    if (this.selectedFileName.indexOf('.xlsx') > -1) {
      flName = this.selectedFileName.replace('.xlsx', '');
    } else if (this.selectedFileName.indexOf('.xls') > -1) {
      flName = this.selectedFileName.replace('.xls', '');
    } else if (this.selectedFileName.indexOf('.xlsm') > -1) {
      flName = this.selectedFileName.replace('.xlsm', '');
    } else if (this.selectedFileName.indexOf('.xlsb') > -1) {
      flName = this.selectedFileName.replace('.xlsb', '');
    } else {
      flName = this.selectedFileName;
    }
    this.fileName =
      flName +
      '_validations_' +
      moment(new Date()).format('MM_DD_YYYY') +
      '.txt';
    this.createDownloadLink(this.panelmessages.join().replaceAll(',', '\n'));
  }

  createDownloadLink(content: string, type = 'text/plain') {
    const file = new Blob([content], { type });
    this.errorDownloadLink = URL.createObjectURL(file);
  }

  showNextMessage(currentMessageCount: number) {
    this.panelmessagesPartial =
      this.panelmessages[currentMessageCount].toString();
    this.currentMessageCount = currentMessageCount + 1;
  }

  showPreviousMessage(currentMessageCount: number) {
    this.panelmessagesPartial =
      this.panelmessages[currentMessageCount - 2].toString();
    this.currentMessageCount = currentMessageCount - 1;
  }

  closeMessage() {
    this.disposeEvent.emit();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }
}
