import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DomService {
  private textArea: HTMLTextAreaElement;

  constructor() {
    const textArea = document.createElement('textarea');
    textArea.style.position = 'absolute';
    textArea.style.top = '-1px';
    textArea.style.width = '0px';
    textArea.style.height = '0px';
    textArea.style.zIndex = '-1000';

    this.textArea = textArea;
    document.body.appendChild(textArea);
  }

  /*
   * Clipboard copying works by creating an input element, pushing text content inside it, selecting the element
   * and issuing copy command to the browser.
   */
  public copyTextToClipboard(text: string): boolean {
    try {
      this.textArea.value = text;
      this.textArea.select();
      return document.execCommand('copy');
    } catch (e) {
      return false;
    } finally {
      this.textArea.value = '';
    }
  }

  public saveOrOpenFile(fileName: string, file: Blob): void {
    const fileUrl: string = URL.createObjectURL(file);
    const linkElement = document.createElement('a');
    linkElement.href = fileUrl;
    linkElement.download = fileName;
    linkElement.target = '_blank';
    document.body.appendChild(linkElement);
    linkElement.click();
    document.body.removeChild(linkElement);
  }
}
