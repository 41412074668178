import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[gridRowHoverBg]',
})
export class GridRowHoverBgDirective {
  // directive to change the background color of a row when the mouse hovers over/out it
  // odd row color #ffffff
  // even row color #F5F5F5
  // hover color #F1FFEC
  // expanded row color #F1FFEC

  constructor(private el: ElementRef) {}

  ngOnInit() {
    if (this.isOddRow(this.el.nativeElement.id)) {
      this.el.nativeElement.style.backgroundColor = '#F5F5F5';
    } else {
      this.el.nativeElement.style.backgroundColor = '#ffffff';
    }
  }

  @HostListener('mouseenter', ['$event']) mouseenter(event: KeyboardEvent) {
    const e = <any>event;
    if (e.target.class !== 'expandRow') {
      this.el.nativeElement.style.backgroundColor = '#F1FFEC';
    }
  }

  @HostListener('mouseout', ['$event']) mouseout(event: KeyboardEvent) {
    if (this.isOddRow(this.el.nativeElement.id)) {
      this.el.nativeElement.style.backgroundColor = '#F5F5F5';
    } else {
      this.el.nativeElement.style.backgroundColor = '#ffffff';
    }
  }

  isOddRow(id: string): boolean {
    const num = parseInt(id);
    return num % 2 === 0;
  }
}
export { ElementRef };
