<section
  class="auth-header-container pr d-flex justify-content-between align-items-center shadow-sm"
>
  <div class="auth-logo-container">
    <img src="assets\icons\nacha-logo.svg" width="150px" />
  </div>
  <div class="support-container d-flex align-items-center">
    <div class="support-txt">Support:</div>
    <div class="support-contact fw-500">(703) 349-4556</div>
  </div>
</section>
