<modal
  *ngIf="isModalOpen"
  [modalConfiguration]="modalConfiguration"
  (modalCloseEvent)="ExtendSession()"
>
  <div class="px-3 mt-2">
    <p>Your session will expire in {{ timeRemaining }}</p>
    <p>
      To continue with the same session, please
      <strong>Extend Session</strong> otherwise you will be signed out
      automatically.
    </p>
  </div>
</modal>
