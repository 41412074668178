import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight',
})
export class HighlightPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  public transform(text: string, search: string): SafeHtml {
    if (!search) {
      return text;
    }

    const regex = new RegExp(
      this.sanitizer.sanitize(SecurityContext.HTML, search) ?? '',
      'gi',
    );
    const match = text.match(regex);

    if (!match) {
      return text;
    }

    return text.replace(regex, `<span class='fw-700'>${match[0]}</span>`);
  }
}
