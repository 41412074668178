import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[faxNumber]',
})
export class FaxNumberDirective {
  // directive that will accept only numbers and hyphen(-) for fax number
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const e = <any>event;
    if (
      e.target.id === 'FaxNumber' ||
      e.target.id === 'taxId' ||
      e.target.id === 'portalRegFax'
    ) {
      const allowedKeysWithoutControl = [
        'Backspace',
        'Delete',
        'ArrowLeft',
        'ArrowRight',
        'Tab',
        'Control',
      ];
      const allowedKeysWithControl = [
        ...allowedKeysWithoutControl,
        'a',
        'c',
        'v',
        'x',
      ];
      if (
        (!e.ctrlKey &&
          !allowedKeysWithoutControl.includes(event.key) &&
          !this.isValidInput(event.key)) ||
        (!allowedKeysWithControl.includes(event.key) &&
          !this.isValidInput(event.key))
      ) {
        event.preventDefault();
      }
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const e = <any>event;
    if (
      e.target.id === 'FaxNumber' ||
      e.target.id === 'taxId' ||
      e.target.id === 'portalRegFax'
    ) {
      const clipboardData: any = event.clipboardData?.getData('text');
      if (!this.isValidInput(clipboardData)) {
        event.preventDefault();
      }
    }
  }

  private isValidInput(input: string | null): boolean {
    if (input === null) {
      return false;
    }
    const pattern = /^[0-9-]+$/;
    return pattern.test(input);
  }
}
