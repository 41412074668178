<div class="tou-cotainer">
  <div class="tou-modal-window">
    <div class="tou-header">
      <span class="fw-700">Terms of Use</span>
      <img
        *ngIf="ShowCloseButton"
        class="c-p"
        src="./assets/icons/close-white.svg"
        alt=""
        (click)="RejectTOU()"
      />
    </div>
    <div class="tou-modal-body">
      <div class="tou-inner-body">
        <div class="tou-body-container">
          <div class="tou-heading">
            RISK MANAGEMENT PORTAL <br />TERMS OF USE
          </div>
          <div class="tou-subhead">
            PLEASE READ THESE TERMS CAREFULLY. THEY GOVERN YOUR USE OF NACHA'S
            RISK MANAGEMENT PORTAL AND ALL RELATED FUNCTIONALITIES, INCLUDING
            THE ACH CONTACT REGISTRY, THIRD-PARTY SENDER REGISTRATION, DIRECT
            ACCESS REGISTRATION, TERMINATED ORIGINATOR DATABASE AND SECURE EMAIL
            SERVICE.
          </div>
          <ol>
            <li>
              <span class="underline-txt">Acceptance of Terms; Amendment</span>.
              The Risk Management Portal (“Portal”), including any website or
              other technology through which the database is offered, is
              administered by Nacha for subscribing institutions. If you use the
              Portal (“Subscriber” or “you”), you agree to be bound by these
              Terms of Use (“Terms”). You hereby represent and warrant that you
              are a Participating Depository Financial Institution, Third-Party
              Sender, or Third-Party Service Provider in the ACH Network, and
              that you have full right, power and authority to enter into and
              comply with these Terms. You agree to comply with all applicable
              laws and regulations in connection with your use of the Portal.
              Nacha reserves the right to periodically amend or modify these
              Terms by making a copy available to you. An updated version of the
              Terms shall be effective with respect to all of your usage of the
              Portal after the date such updated Terms are first made available
              to you. Capitalized terms that are used herein, but are not
              specifically defined in this document, have the meanings assigned
              to them in the Nacha Operating Rules as amended by Nacha from time
              to time.
            </li>
            <li>
              <span class="underline-txt">Access Requirements</span>. Each
              “Authorized User” (“You”) must have an email address with a domain
              name assigned to you and meet such other authentication
              requirements as are required by Nacha from time to time. You are
              responsible for all acts and omissions of Authorized Users with
              respect to the Portal, and for the compliance of Authorized Users
              with these Terms. In connection with any Authorized User obtaining
              a Portal account, you represent and warrant that: (a) all required
              registration information you submit is truthful and accurate; (b)
              you will maintain the accuracy of such information. You agree to
              use the security procedures promulgated by Nacha from time to time
              to control access to the Portal and preserve the confidentiality
              and security of the data contained therein. You are responsible
              for maintaining in strict confidence any security credentials
              provided to any Authorized User for purposes of using the Portal,
              and you shall not to share such security credentials except within
              your institution as necessary to use the Service. You are solely
              responsible for all activity that occurs using those security
              credentials. You must promptly notify Nacha of any unauthorized
              use of your security credentials, or any other breach of security
              or potentially unauthorized or impermissible activity relating to
              the Portal of which you become aware. <br /><br />
              You agree not to change, disrupt or distort the functioning of the
              Portal, to solicit another Subscriber’s security credentials, or
              otherwise act in a way that interferes with other Subscribers’ use
              of the site. You may not access the Portal via any outside service
              provider, consultant, agent or other third party, nor may you or
              shall you access the Portal for, or distribute any information
              contained herein, to any third party. You shall not and may not
              obtain or attempt to obtain any materials or information through
              any means not intentionally made available to Subscribers through
              the Portal.
              <br /><br />
              Nacha has the right to disable any username, password, or other
              identifier, whether chosen by you or provided by Nacha, at any
              time in Nacha's sole discretion for any or no reason, including
              if, in Nacha's opinion, you have violated any provision of these
              Terms.
            </li>
            <li>
              <span class="underline-txt"
                >ACH Contact Registry; Third-Party Sender Registration; Direct
                Access Status Registration</span
              >. If you are an ODFI or RDFI registering contact information for
              ACH Operations or ACH Risk/Fraud, or an ODFI registering your
              Third-Party Sender relationships (or acknowledging that you do not
              have any TPS relationships), or an ODFI registering your Direct
              Access Debit Participant Status by providing specific information
              about each Direct Access Debit Participant (or acknowledging that
              you have no Direct Access Debit Participants), you warrant and
              represent that such information is truthful, accurate, and
              complete. You agree to promptly update such information as
              necessary to ensure its accuracy and complete.
            </li>
            <li>
              <span class="underline-txt"
                >Terminated Originator Database ("TOD")</span
              >. If you register for the TOD, you acknowledge and agree that the
              TOD is intended solely to gather from, and make available to,
              Subscribers, information regarding certain Originators and/or
              Third-Party Senders whose relationship with an ODFI or Third-Party
              Service Provider has been terminated. Each Subscriber agrees to
              post to the TOD information regarding its terminated Originators
              and Third-Party Senders in accordance with the user operating
              instructions and requirements made available to you. By posting
              information to the TOD, you represent and warrant that such
              information is truthful, accurate, and complete, and that the
              posting ofsuch information is not a violation of any agreement or
              legal obligation to which you are subject or by which you are
              bound. If there is any error or omission in any information that
              you post, you shall promptly correct such error or omission. Nacha
              has no obligation to review the information posted to the TOD, and
              Nacha does not make any representations or warranties with respect
              to information in the TOD, including but not limited how accurate
              or current such information may be. You agree that Nacha may
              access and use the TOD, including information posted therein, to
              administer the ACH Network. <br /><br />
              Third-Party Service Providers may be restricted from access to TOD
              data regarding Third-Party Senders.
              <br /><br />
              You should not base underwriting decisions or actions on
              information contained in or accessed through the TOD, but rather
              should perform your own due diligence on each prospective
              Originator and ThirdParty Sender. Inclusion of information about
              an Originator or Third-Party Sender in the TOD does not prohibit
              you or other Subscribers from doing business with such an
              Originator or Third-Party Sender, or serve to indicate any opinion
              of Nacha as to the Originator or Third-Party Sender. You agree to
              use the TOD solely as one factor, and not the exclusive factor, in
              your decision regarding the amount of due diligence to conduct
              with respect to a prospective Originator or Third-Party Sender.
              Nacha reserves the right to make changes to the TOD from time to
              time in its sole discretion, but you acknowledge and agree that it
              has no obligation to do so. Nacha may notify you of any changes it
              may make to the TOD, from time to time, by email or otherwise, but
              you agree that Nacha has no obligation to provide such
              notification to you. You agree to promptly update all your contact
              and other information provided to Nacha in connection with your
              use of the TOD.
              <br /><br />
              Each Subscriber agrees that its designated contact information may
              be provided to a terminated Originator or Third-Party Sender whose
              name the Subscriber has contributed to the TOD.
              <br /><br />
              If the TOD is terminated for any reason, you agree that Nacha may
              transfer information you have posted to the TOD to another TOD
              provider.
            </li>
            <li>
              <span class="underline-txt">Secure Email</span>. If you use the
              Secure Email service feature in the Portal, you acknowledge and
              agree that forms and other materials drafted and sent using this
              service, including indemnity agreements, are stored by a third
              party vendor and are not stored by Nacha. Nacha is not responsible
              for the investigation, defense, settlement and discharge of any
              third party claims that your use of Secure Email in the Portal
              infringes on that third party's intellectual property rights. You
              agree to comply with the applicable third party terms and
              conditions as may be amended from time to time when using the
              Secure Email Service in the Portal:<br />
              DocuSign MSA:
              https://www.docusign.com/company/terms-and-conditions/msa;
              DocuSign Terms:
              https://www.docusign.com/company/terms-and-conditions/msa-service-schedules
              <br /><br />
              While drafting and sending forms using Secure Email in the Portal,
              you are responsible for ensuring the accuracy of information in
              the form and that the forms are used only for the purposes
              permitted by the Nacha Operating Rules. Nacha shall not be
              responsible or liable for the accuracy or availability of any
              information transmitted or made available via the Secure Email in
              the Portal, and shall not be responsible or liable for any error
              or omissions in that information.
            </li>
            <li>
              <span class="underline-txt">Use Obligations</span>. You
              acknowledge and agree that the information transmitted, accessed
              and/or obtained from or through the Portal is confidential
              information that you may only use, disclose, disseminate or copy
              in strict accordance with these Terms. You may not share any
              information obtained through the Portal outside of your
              institution. You may not and shall not publish, disseminate,
              distribute or copy such information. You agree to use the Portal
              solely for your own internal business purposes in accordance with
              these Terms and solely in conformance with your obligations
              herein. You are solely responsible for all your acts and omissions
              in connection with, or reliance on, any information that you
              access, at any time, past or present, through the Portal.
              <br /><br />
              You agree not to use the Portal to collect, upload, transmit,
              display, or distribute any content (i) that violates any third
              party right, including any copyright, trademark, patent, trade
              secret, moral right, privacy right, right of publicity, or any
              other intellectual property or proprietary right; (ii) that is
              unlawful, harassing, abusive, tortious, threatening, harmful,
              invasive of another’s privacy, vulgar, defamatory, false,
              intentionally misleading, trade libelous, pornographic, obscene,
              patently offensive, promotes racism, bigotry, hatred, or physical
              harm of any kind against any group or individual or is otherwise
              objectionable; (iii) that is harmful to minors in any way; or (iv)
              that is in violation of any law, regulation, or obligations or
              restrictions imposed by any third party.
              <br /><br />
              You further agree not to: (i) upload, transmit, or distribute to
              or through the Portal any computer viruses, worms, malware or any
              software intended to damage or alter a computer system or data;
              (ii) send through the Portal unsolicited or unauthorized
              advertising, promotional materials, junk mail, spam, chain
              letters, pyramid schemes, or any other form of duplicative or
              unsolicited messages, whether commercial or otherwise; (iii) use
              the Portal to harvest, collect, gather or assemble information or
              data regarding other users, including e-mail addresses, without
              their consent; (iv) interfere with, disrupt, or create an undue
              burden on servers or networks connected to the Portal, or violate
              the regulations, policies or procedures of such networks; (v)
              attempt to gain unauthorized access to the Portal (or to other
              computer systems or networks connected to or used together with
              the Portal), whether through password mining or any other means;
              (vi) harass or interfere with any other user’s use and enjoyment
              of the Portal; or (vi) use software or automated agents or scripts
              to produce multiple accounts on the Portal, or to generate
              automated searches, requests, or queries to (or to strip, scrape,
              or mine data from) the Portal.
            </li>
            <li>
              <span class="underline-txt">Disputes</span>. Nacha is not
              responsible for the resolution of any dispute between any
              Subscribers or any Authorized Users.
            </li>
            <li>
              <span class="underline-txt">Disclaimer of Warranties.</span> All
              use of the Portal is at your own risk and you agree to bear
              allsuch risk, related costs and liability, and be responsible for
              your use of all information that you access or obtain through the
              Portal as a condition of your right to access the Portal. Nacha
              assumes no responsibility for any errors, omissions or
              inaccuracies whatsoever in the information provided in the Portal.
              The Portal, including all information contained in, accessed or
              obtained through the Portal is provided “AS IS” and "AT YOUR OWN
              RISK" without representation or warranty of any kind, whether
              express or implied. NACHA HEREBY DISCLAIMS ALL REPRESENTATIONS AND
              WARRANTIES, WHETHER STATUTORY, EXPRESS OR IMPLIED, INCLUDING ANY
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              ERROR-FREE OPERATION (EVEN IF CREATED BY THE INTERNATIONAL SALE OF
              GOODS CONVENTION), NON-INFRINGEMENT, OR IMPLIED ANY ARISING FROM
              COURSE OF DEALING OR COURSE OF PERFORMANCE.
            </li>
            <li>
              <span class="underline-txt">Limitation of Liability</span>. Under
              no circumstances will Nacha be liable for any loss or damage
              caused by your reliance on information transmitted, accessed or
              obtained from or through the Portal. It is your sole
              responsibility to evaluate any such information, including for
              timeliness, accuracy, completeness and usefulness. NACHA’S
              LIABILITY UNDER THESE TERMS OR WITH RESPECT TO THE PORTAL IS
              LIMITED IN ALL CASES AND IN THE AGGREGATE TO THE GREATER OF (I)
              THE AMOUNT OF FEES ACTUALLY PAID BY YOU FOR YOUR USE OF THE TOD
              DURING THE TWELVE (12) MONTHS PRECEDING THE DATE OF THE EVENT THAT
              IS THE BASIS FOR THE FIRST CLAIM AND (II) ONE THOUSAND DOLLARS
              ($1,000). In no event shall Nacha be liable for any special,
              incidental, indirect, punitive or consequential damages of any
              kind, whether in contract, tort, strict liability or otherwise
              arising out of or in connection with the use of, or inability to
              use the Portal, whether or not Nacha has been advised of the
              possibility of such damages. This limitation of liability is
              comprehensive and shall apply to all damages of any kind,
              including loss of data, loss of income or profit, loss of or
              damage to property and claims of third parties.
            </li>
            <li>
              <span class="underline-txt">Indemnification</span>. You agree to
              defend, indemnify, and hold harmless Nacha and its directors,
              officers, employees, agents and contractors from and against all
              losses, claims, threatened claims, liabilities, damages, costs and
              expenses, including reasonable attorneys’ fees, of any kind that
              arise from your use or misuse of the Portal, including use of data
              or information contained therein or accessed or transmitted
              thereby, your non-compliance with these Terms, or your violation
              of any third party rights.
            </li>
            <li>
              <span class="underline-txt">Intellectual Property Rights</span>.
              All property or other proprietary rights, including patents,
              designs, trademarks, copyright or trade-secrets, relating to the
              Portal, including software and information, are the exclusive
              property of Nacha and its licensors. Nacha and its licensors, as
              applicable, will retain all right, title and interest in and to
              all intellectual property rights in the information available
              through the Portal. Any rights not expressly granted herein are
              reserved.
            </li>
            <li>
              <span class="underline-txt">Term and Termination</span>. If the
              Portal is used by you in a way in which Nacha, in its sole
              discretion, deems to violate these Terms, Nacha may take any
              action it deems necessary in its sole discretion, including
              temporary or permanent removal of content, filtering of Internet
              transmissions, and the immediate suspension or termination of all
              or any portion of your use of the Portal. You expressly
              acknowledge and agree that Nacha is permitted to perform these
              actions or temporarily or permanently discontinue the Portal or
              your access to the Portal, at any time and shall not be liable in
              any way for any such action. <br /><br />
              Nacha reserves the right to investigate suspected violations of
              these Terms. You hereby authorize Nacha to cooperate with (a) law
              enforcement authorities in the investigation of suspected criminal
              violations and (b) system administrators at Internet service
              providers, networks or computing facilities in order to enforce
              these Terms. Such cooperation may include providing the username,
              IP address, or other identifying information about Subscribers.
              Nacha reserves the right at all times to disclose any information
              as Nacha deems necessary to satisfy any applicable law,
              regulation, legal process or governmental request. Nacha further
              reserves the right to edit, modify or delete any information or
              materials regarding the Portal in connection with any applicable
              law, regulation, legal process or governmental request, but is
              under no obligation to do so.
              <br /><br />
              Notwithstanding the foregoing, Nacha reserves the right, in its
              sole discretion, to terminate Subscribers’ access to the Portal or
              any portion of either, for any reason without notice. Subscriber
              may terminate its participation in the Portal at any time upon
              prior written notice to Nacha.
              <br /><br />
              These Terms continue to govern any rights and obligations with
              respect to your use of the Portal prior to termination of such
              use.
            </li>
            <li>
              <span class="underline-txt">Governing Law</span>. These Terms will
              be governed by and construed in accordance with U.S. federal law
              and the laws of New York, without regard to any principles of
              conflicts of law. You agree that any action at law or in equity
              that arises out of or relates to these Terms or the Portal will be
              filed only in the state or federal courts located in Delaware.
            </li>
            <li>
              <span class="underline-txt">Complete Agreement</span>. These
              Termsrepresent the entire understanding relating to the use of the
              Portal and prevail over any prior or contemporaneous, conflicting
              or additional, communications with respect to the subject matter
              hereof. The parties do not intend that any third party be a
              beneficiary of these Terms.
            </li>
            <li>
              <span class="underline-txt">Assignment</span>. You may not
              transfer or assign (voluntarily, involuntarily, by operation of
              law or otherwise) these Terms or any of your rights or obligations
              under these Terms, in whole or in part, without the prior written
              consent of Nacha, such consent not to be unreasonably withheld.
              Any such purported assignment is a violation of this provision
              shall be voidable at the discretion of Nacha. Any permitted
              successor or assignee shall be bound by these Terms.
            </li>
            <li>
              <span class="underline-txt">Severability</span>. If any provision
              of these Terms shall for any reason and to any extent be
              determined by any court or other entity of competent jurisdiction
              to be invalid or unenforceable, the remaining provisions of these
              Terms shall be interpreted so as best to reasonably effect the
              intent of the parties. The parties further agree that any such
              invalid or unenforceable provisions shall be deemed replaced with
              valid and enforceable provisions that, to the extent possible, are
              coextensive with the business purposes and intent of such invalid
              and unenforceable provisions.
            </li>
            <li>
              <span class="underline-txt">Interpretation</span>. As used herein,
              (i) the terms “include” and “including” are meant to be inclusive
              and shall be deemed to mean “include without limitation” or
              “including without limitation,” (ii) the word “or” is disjunctive,
              but not necessarily exclusive, (iii) words used herein in the
              singular, where the context so permits, shall be deemed to include
              the plural and vice versa, and (iv) any term defined in a
              particular tense shall include other tenses of that term. The
              headings of these Terms are intended solely for convenience of
              reference and shall be given no effect in the interpretation or
              construction of these Terms.
            </li>
            <li>
              <span class="underline-txt">Electronic Communications</span>. The
              communications between you and Nacha use electronic means, whether
              you use the Portal or send us emails, or whether Nacha posts
              notices on the Portal or communicates with you via email. For
              contractual purposes, you (a) consent to receive communications
              from Nacha in an electronic form; and (b) agree that all terms and
              conditions, agreements, notices, disclosures, and other
              communications that Nacha provides to you electronically satisfy
              any legal requirement thatsuch communications would satisfy if it
              were in a hardcopy writing. The foregoing does not affect your
              non-waivable rights.
            </li>
            <li>
              <span class="underline-txt">Contacting Us</span>. Please contact
              us at {{ 'rmportal@nacha.org' }} with any questions about these
              Terms <br /><br />
              Effective Date: 6/1/2022
            </li>
          </ol>
        </div>
      </div>
    </div>
    <div class="tou-modal-footer">
      <button type="button" class="button button-light" (click)="PrintTOU()">
        <img src="./assets/icons/print.svg" alt="" />
        Print
      </button>
      <button
        *ngIf="ShowCloseButton"
        type="button"
        class="button button-light"
        (click)="RejectTOU()"
      >
        Reject
      </button>
      <button
        type="button"
        class="button button-dark alertbtnwidth"
        (click)="AcceptTOU()"
      >
        Accept
      </button>
    </div>
  </div>
</div>
