import { Injectable } from '@angular/core';
import { DatePick } from '../models/DatePickDto';
import { DateFilter } from '../models/DateFilter';
import { SelectItem } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  private readonly dateObjects: DatePick[] = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Last 7 days' },
    { id: 2, name: 'Last 30 days' },
    { id: 3, name: 'This month' },
    { id: 4, name: 'Last month' },
    { id: 5, name: 'Date range' },
  ];

  GetQuarterlyDropdownOptions(): SelectItem[] {
    return [
      { label: '1st Qtr (Jan-Mar)', value: 1 },
      { label: '2nd Qtr (Apr-Jun)', value: 2 },
      { label: '3rd Qtr (Jul-Sep)', value: 3 },
      { label: '4th Qtr (Oct-Dec)', value: 4 },
    ];
  }

  GetDateDropdownOptions(): DatePick[] {
    return this.dateObjects;
  }

  GetDefaultDropdownOption(): DatePick {
    return this.dateObjects[0];
  }

  ConstructDateFilter(
    selectedDateObject: DatePick,
    dateRange?: Date[],
  ): DateFilter {
    const returnable: DateFilter = {};

    const d = new Date();

    switch (selectedDateObject.id) {
      case 1:
        returnable.dateFrom = new Date(d.getTime() - 7 * 24 * 60 * 60 * 1000);
        returnable.dateTo = new Date();
        break;
      case 2:
        returnable.dateFrom = new Date(d.getTime() - 30 * 24 * 60 * 60 * 1000);
        returnable.dateTo = new Date();
        break;
      case 3:
        returnable.dateFrom = new Date(d.getFullYear(), d.getMonth(), 1);
        returnable.dateTo = new Date(d.getFullYear(), d.getMonth() + 1, 0);
        break;
      case 4:
        returnable.dateFrom = new Date(d.getFullYear(), d.getMonth() - 1, 1);
        returnable.dateTo = new Date(d.getFullYear(), d.getMonth(), 0);
        break;
      case 5:
        returnable.dateFrom = dateRange?.[0];
        returnable.dateTo = dateRange?.[1];
        break;
      default:
        break;
    }

    if (returnable.dateFrom) {
      returnable.dateFrom.setHours(0, 0, 0, 0);
    }

    if (returnable.dateTo) {
      returnable.dateTo.setHours(23, 59, 59, 999);
    }

    return returnable;
  }
}
