<table class="table mt-3 align-middle mb-0 bg-white">
  <thead class="bg-light">
    <tr>
      <th *ngFor="let head of HeaderList">{{ head }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of TableData">
      <td *ngFor="let head of HeaderList">
        {{ item[head] }}
      </td>
    </tr>
  </tbody>
</table>
