<div>
  <div [ngClass]="messageContainerClass">
    <div class="message pl-spacer d-flex align-items-center">
      <div
        *ngIf="showAllMessages; else elseBlock"
        class="d-flex col-12 fullMessages"
      >
        <div class="col-10">
          <ng-container
            *ngFor="let message of panelmessages; index as i"
            class="message"
          >
            <div *ngIf="message" class="d-flex">
              <img
                *ngIf="successIconDisplay"
                class="bi bi-exclamation-circle me-2"
                src="assets\icons\success_icon.svg"
                alt="Success icon"
              />

              <img
                *ngIf="errorIconDisplay"
                class="bi bi-exclamation-circle me-2"
                src="assets\icons\error_icon.svg"
                alt="Error icon"
              />

              <img
                *ngIf="warningIconDisplay"
                class="bi bi-exclamation-circle me-2"
                src="assets\icons\warning_icon.svg"
                alt="Warning icon"
              />

              <img
                *ngIf="infoIconDisplay"
                class="bi bi-exclamation-circle me-2"
                src="assets\icons\info_icon.svg"
                alt="Info icon"
              />
              <span class="ps-1 d-inline-block pt-2 pb-2"
                >{{ i + 1 }} of {{ totalMessageCount }}: {{ message }}
              </span>
            </div>
          </ng-container>
        </div>

        <div
          class="col-2 d-flex justify-content-end align-items-start error-action-container"
        >
          <div class="d-inline-flex justify-content-end align-items-center">
            <div *ngIf="downloadIconVisible">
              <a
                [href]="errorDownloadLink"
                class="downloadError c-p d-flex justify-content-center align-items-center"
                [download]="fileName"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Download errors in .txt format."
              >
                <img src="./assets/icons/download-dark.svg" alt="" />
              </a>
              <div class="pipe-line"></div>
            </div>
            <div *ngIf="totalMessageCount > 1">
              <button
                type="button"
                pButton
                (click)="
                  showAllMessages = !showAllMessages; currentMessageCount = 1
                "
                data-toggle="tooltip"
                data-placement="bottom"
                title="Show less"
              >
                <img
                  src="assets\icons\message_toggle.svg"
                  alt="Message toggle"
                />
              </button>
            </div>
            <div
              (click)="closeMessage()"
              [ngClass]="
                closeIconVisible
                  ? 'col-2 d-flex flex-row-reverse c-p'
                  : 'd-none'
              "
            >
              <img src="assets\icons\message_close.svg" alt="Close message" />
            </div>
          </div>
        </div>
      </div>

      <ng-template #elseBlock>
        <div class="col-10 message partialMessage d-flex">
          <img
            *ngIf="successIconDisplay"
            class="bi bi-exclamation-circle me-2"
            src="assets\icons\success_icon.svg"
            alt="Success icon"
          />

          <img
            *ngIf="errorIconDisplay"
            class="bi bi-exclamation-circle me-2"
            src="assets\icons\error_icon.svg"
            alt="Error icon"
          />

          <img
            *ngIf="warningIconDisplay"
            class="bi bi-exclamation-circle me-2"
            src="assets\icons\warning_icon.svg"
            alt="Warning icon"
          />

          <img
            *ngIf="infoIconDisplay"
            class="bi bi-exclamation-circle me-2"
            src="assets\icons\info_icon.svg"
            alt="Info icon"
          />
          <span *ngIf="totalMessageCount > 1" id="countSpan"
            >{{ currentMessageCount }} of {{ totalMessageCount }}:</span
          >
          <span class="ps-1 d-inline-block pt-2 pb-2"
            >{{ panelmessagesPartial }}
          </span>
        </div>

        <div
          (click)="closeMessage()"
          [ngClass]="
            closeIconVisible ? 'col-2 d-flex flex-row-reverse c-p' : 'd-none'
          "
        >
          <img src="assets\icons\message_close.svg" alt="Close message" />
        </div>

        <div
          [ngClass]="
            totalMessageCount > 1 ? 'col-2 d-flex flex-row-reverse' : 'd-none'
          "
        >
          <button
            type="button"
            pButton
            (click)="showAllMessages = !showAllMessages"
            data-toggle="tooltip"
            data-placement="bottom"
            title="Show more"
          >
            <img
              class="me-2 ms-2"
              src="assets\icons\message_toggle.svg"
              alt="Message toggle"
            />
          </button>

          <div class="line-h ms-2"></div>

          <button
            [disabled]="currentMessageCount === totalMessageCount"
            class="p-button-text"
            type="button"
            (click)="showNextMessage(currentMessageCount)"
            pButton
            data-toggle="tooltip"
            data-placement="bottom"
            title="Show next"
            id="next"
          >
            <svg
              id="nexticon"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 16 16"
            >
              <path
                d="M6.94 4L6 4.94L9.05333 8L6 11.06L6.94 12L10.94 8L6.94 4Z"
                [ngClass]="
                  currentMessageCount === totalMessageCount ? 'gray' : 'blue'
                "
              />
            </svg>
          </button>

          <button
            [disabled]="currentMessageCount === 1"
            pbutton
            type="button"
            class="p-button-text"
            (click)="showPreviousMessage(currentMessageCount)"
            pButton
            data-toggle="tooltip"
            data-placement="bottom"
            title="Show previous"
            id="prev"
          >
            <svg
              id="previcon"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 16 16"
            >
              <path
                d="M10.06 12L11 11.06L7.94667 8L11 4.94L10.06 4L6.06 8L10.06 12Z"
                [ngClass]="currentMessageCount === 1 ? 'gray' : 'blue'"
              />
            </svg>
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
