import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { SpinnerService } from 'src/app/modules/common-components/spinner/service/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class CustomHttpClientService {
  private activeRequests = 0;
  constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
  ) {}

  private showSpinner(): void {
    if (this.activeRequests === 0) {
      this.spinnerService.Show();
    }
    this.activeRequests++;
  }

  private hideSpinner(): void {
    this.activeRequests--;
    if (this.activeRequests === 0) {
      this.spinnerService.Hide();
    }
  }

  get<T>(url: string, isSpinnerRequired = true): Observable<T> {
    if (isSpinnerRequired) {
      this.showSpinner();
    }

    return this.http.get<T>(url).pipe(
      finalize(() => {
        if (isSpinnerRequired) {
          this.hideSpinner();
        }
      }),
    );
  }

  delete<T>(url: string, isSpinnerRequired = true): Observable<T> {
    if (isSpinnerRequired) {
      this.showSpinner();
    }

    return this.http.delete<T>(url).pipe(
      finalize(() => {
        if (isSpinnerRequired) {
          this.hideSpinner();
        }
      }),
    );
  }

  put<T>(url: string, body: any, isSpinnerRequired = true): Observable<T> {
    if (isSpinnerRequired) {
      this.showSpinner();
    }

    return this.http.put<T>(url, body).pipe(
      finalize(() => {
        if (isSpinnerRequired) {
          this.hideSpinner();
        }
      }),
    );
  }

  patch<T>(url: string, body: any, isSpinnerRequired = true): Observable<T> {
    if (isSpinnerRequired) {
      this.showSpinner();
    }

    return this.http.patch<T>(url, body).pipe(
      finalize(() => {
        if (isSpinnerRequired) {
          this.hideSpinner();
        }
      }),
    );
  }

  post<T>(url: string, body: any, isSpinnerRequired = true): Observable<T> {
    if (isSpinnerRequired) {
      this.showSpinner();
    }

    return this.http.post<T>(url, body).pipe(
      finalize(() => {
        if (isSpinnerRequired) {
          this.hideSpinner();
        }
      }),
    );
  }

  postFile<T>(url: string, body: any, isSpinnerRequired = true): Observable<T> {
    if (isSpinnerRequired) {
      this.showSpinner();
    }

    return this.http
      .post<T>(url, body, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .pipe(
        finalize(() => {
          if (isSpinnerRequired) {
            this.hideSpinner();
          }
        }),
      );
  }

  request(
    method: string,
    url: string,
    options: any,
    isSpinnerRequired = true,
  ): Observable<any> {
    if (isSpinnerRequired) {
      this.showSpinner();
    }

    return this.http.request(method, url, options).pipe(
      finalize(() => {
        if (isSpinnerRequired) {
          this.hideSpinner();
        }
      }),
    );
  }

  requestMethod(
    req: HttpRequest<any>,
    isSpinnerRequired = true,
  ): Observable<any> {
    if (isSpinnerRequired) {
      this.showSpinner();
    }

    return this.http.request(req).pipe(
      finalize(() => {
        if (isSpinnerRequired) {
          this.hideSpinner();
        }
      }),
    );
  }
}
