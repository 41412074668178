import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { GeneralService } from 'src/app/services/general.service';

@Injectable({
  providedIn: 'root',
})
export class UserSessionService {
  private placeholderSessionTimeInSec = 999999; // Placeholder initial value
  private sessionTimeInSec = this.placeholderSessionTimeInSec; // Placeholder initial value
  public sessionWarningThresholdInSec = 120; // 2 minutes
  private remainingTime: number;
  private isFetchingSessionTime = false;

  private sessionTimeRemainingSubject: BehaviorSubject<number>;
  public sessionTimeRemaining$: Observable<number>;
  private handleMouseMoveBound: ((event: MouseEvent) => void) | undefined;
  private worker: Worker | undefined = undefined;
  private currentSessionId: string | undefined;

  constructor(
    private authService: AuthenticationService,
    private generalService: GeneralService,
  ) {
    this.remainingTime = this.sessionTimeInSec;
    this.sessionTimeRemainingSubject = new BehaviorSubject(this.remainingTime);
    this.sessionTimeRemaining$ =
      this.sessionTimeRemainingSubject.asObservable();
    this.InitializeWorker();
  }

  private HandleMouseMove(event: MouseEvent) {
    if (this.remainingTime > this.sessionWarningThresholdInSec) {
      this.ResetTimer();
      this.StartSessionCountdown();
    }
  }

  private InitializeWorker() {
    if (!this.worker) {
      this.worker = new Worker(
        '../../../assets/workers/session-timer-worker.js',
      );
    }
    this.worker.onmessage = ({ data }) => {
      if (data.sessionId === this.currentSessionId) {
        this.sessionTimeRemainingSubject.next(data.time);
        this.remainingTime = data.time;
        if (this.remainingTime <= 0) {
          this.Logout();
        }
      }
    };
  }

  public StartSessionCountdown(): void {
    this.InitializeWorker();
    if (this.sessionTimeInSec == this.placeholderSessionTimeInSec) {
      if (this.isFetchingSessionTime) {
        return;
      }
      this.isFetchingSessionTime = true;
      this.generalService.GetTimeoutSessionValue().then((time) => {
        this.sessionTimeInSec = time;
        this.remainingTime = this.sessionTimeInSec;
        this.sessionTimeRemainingSubject.next(this.remainingTime);
        this.isFetchingSessionTime = false;
        this.StartWorkerCountdown(this.sessionTimeInSec);
      });
    } else {
      this.StartWorkerCountdown(this.sessionTimeInSec);
    }

    if (!this.handleMouseMoveBound) {
      this.handleMouseMoveBound = this.HandleMouseMove.bind(this);
      document.addEventListener('mousemove', this.handleMouseMoveBound);
    }
  }

  private StartWorkerCountdown(duration: number) {
    if (this.worker) {
      this.currentSessionId = this.GenerateSessionId();
      this.worker.postMessage({
        action: 'START',
        duration: duration,
        sessionId: this.currentSessionId,
      });
    }
  }

  private GenerateSessionId() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  public Logout() {
    this.ResetTimer();
    if (this.handleMouseMoveBound) {
      document.removeEventListener('mousemove', this.handleMouseMoveBound);
      this.handleMouseMoveBound = undefined;
    }
    this.authService.ExternalLogout();
  }

  public GetSessionWarningThresholdInSec(): number {
    return this.sessionWarningThresholdInSec;
  }

  public ExtendSession() {
    this.ResetTimer();
    this.StartSessionCountdown();
  }

  private ResetTimer() {
    if (this.worker) {
      this.worker.postMessage({ action: 'STOP' });
      this.currentSessionId = undefined;
    }
  }
}
