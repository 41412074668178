import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FileResponse } from 'src/app/models/FileResponse';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'tou',
  templateUrl: './tou.component.html',
  styleUrls: ['./tou.component.scss'],
})
export class TouComponent {
  pdfSource: any;

  @Input() ShowCloseButton = true;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly generalService: GeneralService,
  ) {}

  @Output() Print = new EventEmitter();
  @Output() Reject = new EventEmitter();
  @Output() Accept = new EventEmitter();

  PrintTOU() {
    this.Print.emit();
  }

  RejectTOU() {
    this.Reject.emit();
  }

  AcceptTOU() {
    this.Accept.emit();
  }

  ngOnInit() {
    this.generalService.GetTermsOfUserPdf().then((response: FileResponse) => {
      const tempBlob = new Blob([response.data], { type: 'application/pdf' });
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.pdfSource = new Uint8Array(fileReader.result as ArrayBuffer);
        this.cdr.detectChanges();
      };
      fileReader.readAsArrayBuffer(tempBlob);
    });
  }
}
