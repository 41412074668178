<div
  class="d-flex flex-column login-parent-container"
  *ngIf="!isLoggedInProgress"
>
  <auth-header></auth-header>
  <ng-container *ngIf="PortalRegRegisterMessage">
    <alert-message
      class="alertpos"
      [messageText]="PortalRegRegisterMessage"
      [icon]="'check_circle-success.svg'"
      [background]="'alert-success-light success'"
    >
    </alert-message>
  </ng-container>
  <div class="login-page-container">
    <ng-container *ngIf="isScheduleMaintenance">
      <schedule-maintenance></schedule-maintenance>
    </ng-container>
    <div class="row p0 m0 h100">
      <div class="col-md-12 p0 d-flex">
        <div
          class="w67 contaner-spacer d-flex flex-column justify-content-between"
        >
          <div class="login-left-container">
            <div class="login-heading fw-400">Risk Management Portal</div>
            <div class="login-text-container mt5">
              Exciting News! The Risk Management Portal just finished a makeover
              and is now more user-friendly and intuitive. Explore the enhanced
              interface, making it easier to contact other users. Using the
              latest version of Microsoft Edge or Google Chrome is recommended
              to maximize the Risk Management Portal’s capabilities.
            </div>
            <div class="login-text-container textSpacer">
              Note, your login credentials for the Risk Management Portal are
              different than Nacha.org and Nacha Operating Rules Online. Do you
              need assistance? Visit the Frequently Asked Questions on this page
              or call the Portal Support number at 703-349-4556.
            </div>
            <div class="login-button-container d-flex flex-column">
              <div class="re-user">Returning Users</div>
              <button-component
                [buttonClass]="'button button-dark w200'"
                [isLeftImg]="false"
                [isRightImg]="false"
                Text="Log In"
                (OnClick)="Login()"
                [isButtonDisabled]="false"
              ></button-component>
            </div>
            <div class="portal-reg-container">
              <div class="login-heading fw-400 new-reg-size">
                New Registration?
              </div>
              <div class="reg-text fw500">
                Through Nacha’s Risk Management Portal that includes the ACH
                Contact Registry, Third-Party Sender Registration, Direct Access
                Registration and the Terminated Originator Database, ACH Network
                participants can help enhance Network quality and security,
                fueling innovation and the continued growth of the ACH Network.
              </div>
            </div>
            <div
              class="pr-item-list-container d-flex flex-wrap justify-content-between"
            >
              <div
                class="pr-item d-flex align-items-center c-p"
                (click)="PortalRegistration(f.type)"
                *ngFor="let f of fiTypeList"
              >
                <span>{{ f.text }}</span>
                <img
                  src="./assets/icons/info.png"
                  alt=""
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="{{ f.tooltip }}"
                />
              </div>
            </div>
          </div>
          <div class="gredi">
            <img src="./assets/images/left.png" alt="" />
            <img src="./assets/images/right.png" alt="" />
          </div>
        </div>
        <div class="w33">
          <div class="banner-img-container">
            <img src="./assets/images/faq-banner.jpg" alt="" />
            <div
              class="faq-main-container d-flex justify-content-center align-items-center"
            >
              <div class="faqbox">
                <div class="faq-heading">FAQs</div>
                <div class="faq-list-container">
                  <div
                    class="faq-item d-flex justify-content-between align-items-center c-p"
                    *ngFor="let f of faqList"
                    (click)="GoToPortalFAQs(f)"
                  >
                    <span>{{ f.text }}</span>
                    <img src="./assets/icons/open-lite.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer [showSupportNumber]="false"></app-footer>
</div>
<div class="login-progress" *ngIf="isLoggedInProgress">
  <div class="progress-box">Your are being logged in...</div>
</div>
