import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[alphaNumeric]',
})
export class AlphaNumericDirective {
  alphaNumericRegex = new RegExp(/^[a-zA-Z0-9]*$/);
  allowedKeycodes: any[] = [
    48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 65, 66, 67, 68, 69, 70, 71, 72, 73,
    74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 96, 97,
    98, 99, 100, 101, 102, 103, 104, 105,
  ];

  constructor(private el: ElementRef) {}

  // directive that will allow to enter alphabets and numbers
  @HostListener('keydown', ['$event']) onKeyDown(event: any) {
    const e = <any>event;
    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Backspace
      (e.keyCode === 8 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Space
      e.keyCode === 32 ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a alphabet and number and stop the keypress
    if (
      this.allowedKeycodes.indexOf(e.keyCode) === -1 ||
      (e.shiftKey && e.keyCode >= 48 && e.keyCode <= 57)
    ) {
      e.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: any) {
    const e = <any>event;
    let val: any;
    navigator['clipboard'].readText().then((data) => {
      val = data;
      if (
        !this.alphaNumericRegex.test(val) &&
        event.target.value.indexOf(val) > -1
      ) {
        e.preventDefault();
        const ele = this.el.nativeElement as HTMLInputElement;
        if (!this.alphaNumericRegex.test(val)) {
          if (event.target.value.indexOf(val) > -1) {
            ele.value = event.target.value.replace(val, '');
          }
        } else {
          return;
        }
      }
    });
  }
}
