<div
  class="filter-wrapper d-flex justify-content-center"
  (clickOutside)="displayContainer = false"
>
  <div class="filter-Container">
    <div class="filter-seperator">
      <svg
        class="c-p"
        xmlns="http://www.w3.org/2000/svg"
        title="Column Configuration"
        (click)="displayContainer = !displayContainer"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          d="M3 13V15H11V13H3ZM3 17V19H11V17H3ZM3 9V11H11V9H3ZM3 5V7H11V5H3ZM13 5H21V19H13V5ZM15 7V17H19V7H15Z"
          fill="#2F6CF6"
        />
      </svg>
    </div>
  </div>
  <div class="column-container" *ngIf="displayContainer">
    <div class="configuration-title fw-500">
      <strong>Column Configuration</strong>
    </div>
    <div class="column-seperator"></div>
    <div class="cofig-data">
      <div *ngFor="let column of inputColumns; let i = index">
        <div class="justify-content-end config-input">
          <input
            type="checkbox"
            [disabled]="column.isDefault"
            [checked]="column.isChecked"
            (change)="onCheckboxChange(column, $event)"
          />
          <label class="config-input-title">{{ column.ValueName }}</label>
        </div>
      </div>
    </div>
    <div class="column-seperator"></div>
    <div class="d-flex justify-content-end">
      <div class="button-spacing">
        <button-component
          [buttonClass]="'button button-light'"
          Text="Cancel"
          [isButtonDisabled]="false"
          (click)="displayContainer = false"
        >
        </button-component>
      </div>
      <button-component
        [buttonClass]="'button button-dark'"
        Text="Ok"
        [isButtonDisabled]="false"
        (click)="OkClick(); displayContainer = false"
      >
      </button-component>
    </div>
  </div>
</div>
