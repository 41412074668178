import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { SpinnerService } from './service/spinner.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements OnInit {
  isLoading = false;

  constructor(
    private spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.spinnerService.isLoading$.subscribe((isLoading) => {
      this.isLoading = isLoading;
      this.cdr.detectChanges();
    });
  }
}
