import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AlertEvent } from '../models/alert-event';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private subject = new Subject<any>();

  ConfirmThis(
    alertEvent: AlertEvent,
    Confirm: (() => void) | undefined = undefined,
    Cancel: (() => void) | undefined = undefined,
    Print: (() => void) | undefined = undefined,
  ): any {
    this.setConfirmation(alertEvent, Confirm, Cancel, Print);
  }

  private setConfirmation(
    alertEvent: AlertEvent,
    Confirm: (() => void) | undefined,
    Cancel: (() => void) | undefined,
    Print: (() => void) | undefined,
  ): any {
    this.subject.next({
      title: alertEvent.title,
      message: alertEvent.message,
      confirmButtonText: alertEvent.confirmButtonText,
      cancelButtonText: alertEvent.cancelButtonText,
      showCancelButton: alertEvent.showCancelButton,
      showPrintButton: alertEvent.showPrintButton,
      printButtonText: alertEvent.printButtonText,
      Confirm: (): any => {
        this.subject.next(''); // This will close the modal
        if (Confirm) {
          Confirm();
        }
      },
      Cancel: (): any => {
        this.subject.next('');
        if (Cancel) {
          Cancel();
        }
      },
      Print: (): any => {
        if (Print) {
          Print();
        }
      },
    });
  }

  GetMessage(): Observable<AlertEvent> {
    return this.subject.asObservable();
  }
}
