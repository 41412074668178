import { Component, Input } from '@angular/core';
import { todWizardDto } from '../../tod/models/todWizardDto';

@Component({
  selector: 'tod-wizard',
  templateUrl: './tod-wizard.component.html',
  styleUrls: ['./tod-wizard.component.scss'],
})
export class TodWizardComponent {
  @Input() wizard: todWizardDto[] = [];
  @Input() activeStep = 1;
  @Input() isAllStepValid = false;

  ngOnChange(changes: any) {
    this.activeStep = changes.activeStep?.currentValue;
    this.isAllStepValid = changes.isAllStepValid?.currentValue;
  }
}
