<div
  class="badge fw-500"
  [ngClass]="
    Text === 'Active'
      ? 'badge-color-active'
      : Text === 'Pending Approval'
        ? 'badge-color-pending'
        : 'badge-color-inactive'
  "
>
  <span>{{ Text }}</span>
</div>
