import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
import { ContactRegistryService } from '../services/contact-registry.service';
import { Subject, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';

@Directive({
  selector: '[phonePattern]',
})
export class PhonePatternDirective {
  @Output() PhoneNumberStatus = new EventEmitter<boolean>();
  subject: Subject<any> = new Subject();

  constructor(
    public el: ElementRef,
    private contactRegistryService: ContactRegistryService,
    private cdr: ChangeDetectorRef,
  ) {}

  // directive that will add (-) at index 4 and 8 in phone Number input
  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent) {
    const e = <any>event;
    const val = this.el.nativeElement.value;
    const splitedArr: any[] = val.split('');
    let arrWithoutHyphen: any[] = [];
    if (val.length > 0 && e.inputType !== 'deleteContentBackward') {
      arrWithoutHyphen = splitedArr.filter((el: string) => {
        return el !== '-';
      });
      if (val.length > 3) {
        arrWithoutHyphen?.splice(3, 0, '-');
      }
      if (val.length > 7) {
        arrWithoutHyphen?.splice(7, 0, '-');
      }
      this.el.nativeElement.value = arrWithoutHyphen.join().replaceAll(',', '');
      this.subject.next('');
    }
  }

  ngOnInit() {
    this.PhoneNumberStatus.emit(true);
    this.subject.pipe(debounceTime(500)).subscribe(() => {
      if (this.el.nativeElement.id !== 'portalRegPhone') {
        this.ValidateTelephoneNumber(this.el.nativeElement.value);
      }
    });
  }

  ValidateTelephoneNumber(phoneNumber: string) {
    this.contactRegistryService
      .ValidateTelephoneNumberAsync(phoneNumber.replaceAll('-', ''))
      .then((res: { isValidNumber: boolean }) => {
        this.PhoneNumberStatus.emit(res.isValidNumber);
        this.cdr.detectChanges();
      });
  }
}
