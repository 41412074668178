import { Injectable, inject } from '@angular/core';
import {
  CanActivateFn,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationDetailsAuthGuard {
  constructor(
    private router: Router,
    private userService: UserService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (
      !this.userService.isAdmin() &&
      !this.userService.isOdfiUser() &&
      !this.userService.isRdfiUser() &&
      !this.userService.isNachaUser()
    ) {
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }
}

export const AuthGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean => {
  return inject(RegistrationDetailsAuthGuard).canActivate(next, state);
};
