<div>
  <div class="pagination-wrapper rounded-1 mt-3">
    <div class="row align-items-center">
      <div class="col-3 g-0">
        <div class="page-size-container d-flex align-items-center">
          <div *ngIf="HasRecords()" class="d-flex">
            <div id="pageSize" class="page-instruction page-text">
              {{ pageFiltered ? filterTitle : '' }} {{ rangeStart }} -
              {{ rangeEnd }} of
            </div>
            <div class="totalPages page-text">{{ totalRecords }} Records</div>
          </div>
          <div *ngIf="pageFiltered" class="d-flex align-items-center">
            <div *ngIf="HasRecords()" class="line-h"></div>
            <div class="clear-filter">
              <a class="hyperLink" (click)="ClearFilter()"> Clear Filter </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9" *ngIf="HasRecords()">
        <div
          class="pagination-container d-flex justify-content-end align-items-center"
        >
          <div class="pagination-spacer page-text ml-10">Page Size</div>
          <select
            class="pageSelectBox"
            [(ngModel)]="paginationConfig.activePageSize"
            (change)="SetPageSize($event)"
          >
            <option
              *ngFor="let p of pageSizeList; let i = index"
              value="{{ p }}"
            >
              {{ p }}
            </option>
          </select>
          <div class="pagination-spacer page-text ml-10">Page</div>
          <div
            class="active-page d-flex justify-content-center align-items-center"
          >
            <input
              type="text"
              class="pageInputBox text-center"
              [(ngModel)]="paginationConfig.pageNumber"
              id="pageNumber"
              (input)="OnPageInput($event)"
            />
          </div>
          <div class="pagination-spacer page-text">of</div>
          <div id="totalPageNumber" class="page-text">
            {{ totalPageNumber }}
          </div>
          <div
            class="d-flex align-items-center pagination-button-container ml-10"
          >
            <button
              [ngClass]="
                isGotoDisabled ? 'button button-gray' : 'button button-dark'
              "
              [disabled]="isGotoDisabled"
              (click)="GoToPage()"
              type="button"
              value="GoTo"
            >
              Go to Page
            </button>
            <div class="pagination-action c-p hover ml-10">
              <svg
                id="prev"
                xmlns="http://www.w3.org/2000/svg"
                (click)="OnPageChange('prev')"
                width="22"
                height="22"
                viewBox="0 0 16 16"
              >
                <path
                  d="M10.06 12L11 11.06L7.94667 8L11 4.94L10.06 4L6.06 8L10.06 12Z"
                  [ngClass]="
                    paginationConfig.pageNumber === 1 ? 'gray' : 'blue'
                  "
                />
              </svg>
            </div>
            <div class="pagination-action c-p ml-10">
              <svg
                id="next"
                xmlns="http://www.w3.org/2000/svg"
                (click)="OnPageChange('next')"
                width="22"
                height="22"
                viewBox="0 0 16 16"
              >
                <path
                  d="M6.94 4L6 4.94L9.05333 8L6 11.06L6.94 12L10.94 8L6.94 4Z"
                  [ngClass]="
                    paginationConfig.pageNumber >= totalPageNumber
                      ? 'gray'
                      : 'blue'
                  "
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
