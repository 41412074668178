import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';

@Component({
  selector: 'button-component',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnChanges {
  @Input() Id = '';
  @Input() Text = '';
  @Input() buttonClass!: string;
  @Input() isLeftImg!: boolean;
  @Input() isRightImg!: boolean;
  @Input() buttonImage!: string;
  @Input() isButtonDisabled!: boolean;
  @Output() OnClick = new EventEmitter<unknown>();

  ngOnChanges(changes: any) {
    if (changes.buttonImage?.currentValue) {
      this.buttonImage = changes.buttonImage.currentValue;
    }

    if (changes.isLeftImg?.currentValue) {
      this.isLeftImg = changes.isLeftImg.currentValue;
    }

    if (changes.isRightImg?.currentValue) {
      this.isRightImg = changes.isRightImg.currentValue;
    }

    if (changes.buttonClass?.currentValue) {
      this.buttonClass = changes.buttonClass.currentValue;
    }

    if (changes.isButtonDisabled?.currentValue) {
      this.isButtonDisabled = changes.isButtonDisabled.currentValue;
    }
  }

  public ClickEvent() {
    this.OnClick.emit();
  }
}
