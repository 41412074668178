import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
@Directive({
  selector: '[faxNumberWithHyphen]',
})
export class FaxNumberWithHyphenDirective {
  constructor(
    public el: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {}

  // directive that will add (-) at index 4 and 8 in fax Number input
  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent) {
    const e = <any>event;
    const val = this.el.nativeElement.value;
    const splitedArr: any[] = val.split('');
    let arrWithoutHyphen: any[] = [];
    if (val.length > 0) {
      if (e.inputType !== 'deleteContentBackward') {
        arrWithoutHyphen = splitedArr.filter((el: string) => {
          return el !== '-';
        });
        if (val.length > 3) {
          arrWithoutHyphen?.splice(3, 0, '-');
        }
        if (val.length > 7) {
          arrWithoutHyphen?.splice(7, 0, '-');
        }
        this.el.nativeElement.value = arrWithoutHyphen
          .join()
          .replaceAll(',', '');
      }
    }
  }
}
