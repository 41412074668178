import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomHttpClientService } from 'src/app/infrastructure/services/custom-http-client.service';

export class SummaryDetails {
  fiType?: string;
  summary?: string;
}

@Injectable()
export class RegistrationSummaryService {
  constructor(private http: CustomHttpClientService) {}

  async GetFiTypesAsync(): Promise<any[]> {
    return lastValueFrom(
      this.http
        .get<any[]>(`api/select-list/RegistrationSummary`)
        .pipe(map((data: any[]) => data)),
    );
  }

  async GetVersionsAsync(selectedUserType: string): Promise<any[]> {
    return lastValueFrom(
      this.http
        .get<any[]>(
          `api/registration-summary/versions?fiType=${selectedUserType}`,
        )
        .pipe(map((data: any[]) => data)),
    );
  }

  async GetTemplateDetailsAsync(
    selectedUserType: string,
    selectedVersion: number,
  ): Promise<any> {
    return lastValueFrom(
      this.http
        .get(
          `api/registration-summary/templates?fiType=${selectedUserType}&version=${selectedVersion}`,
        )
        .pipe(map((data: any) => data)),
    );
  }

  async GetSummaryDetailsAsync(routingNumber: string): Promise<any> {
    return lastValueFrom(
      this.http
        .get(`api/registration-summary/details?routingNumber=${routingNumber}`)
        .pipe(map((data: any) => data)),
    );
  }

  async SaveSummaryDetailsAsync(summaryDetails: SummaryDetails) {
    return lastValueFrom(
      this.http
        .post(`api/registration-summary/create`, summaryDetails)
        .pipe(map((data: any) => data)),
    );
  }
}
