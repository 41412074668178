import { Component, Input, SimpleChanges } from '@angular/core';

@Component({
  selector: 'input-error-panel',
  templateUrl: './input-error-panel.component.html',
  styleUrls: ['./input-error-panel.component.scss'],
})
export class InputErrorPanelComponent {
  @Input() errorMsg!: string;
  @Input() class = 'successPanel';

  ngOnChanges(changes: SimpleChanges) {
    this.errorMsg = changes['errorMsg']?.currentValue;
  }
}
