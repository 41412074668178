import { ChangeDetectorRef, Component } from '@angular/core';
import { ModalConfiguration } from 'src/app/models/ModalConfiguration';
import { UserSessionService } from 'src/app/infrastructure/authentication/user-session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'user-session-modal',
  templateUrl: './user-session-modal.component.html',
  styleUrls: ['./user-session-modal.component.scss'],
})
export class UserSessionModalComponent {
  userSessionSubscription: Subscription = new Subscription();
  userSessionThreshold = 120;
  timeRemaining = '00:00';

  constructor(
    private userSessionService: UserSessionService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.userSessionSubscription.unsubscribe();
    this.userSessionThreshold =
      this.userSessionService.sessionWarningThresholdInSec;
    this.userSessionService.sessionTimeRemaining$.subscribe((time) => {
      if (time <= this.userSessionThreshold) {
        this.isModalOpen = true;
        this.timeRemaining = this.ToFormattedTime(time);
      }
      if (time < 1) {
        this.isModalOpen = false;
        this.userSessionSubscription.unsubscribe();
      }
      this.cdr.detectChanges();
    });
  }

  isModalOpen = false;

  modalConfiguration: ModalConfiguration = {
    header: 'Session Timeout',
    height: 300,
    width: 450,
    button2: {
      text: 'Extend Session',
      buttonClass: 'button button-dark  me-2',
      callback: () => {
        this.userSessionService.StartSessionCountdown();
        this.isModalOpen = false;
      },
    },
    includeCancelButton: false,
    cancelButtonText: 'Cancel',
    button1: {
      text: 'Logout',
      buttonClass: 'button button-light me-2',
      callback: () => {
        this.userSessionService.Logout();
        this.isModalOpen = false;
      },
    },
    isCloseable: false,
  };

  public ExtendSession() {
    this.isModalOpen = false;
    this.userSessionSubscription.unsubscribe();
  }

  private ToFormattedTime(time: number): string {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes.toString();
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds.toString();
    return `${formattedMinutes}:${formattedSeconds}`;
  }

  ngOnDestroy() {
    this.userSessionSubscription.unsubscribe();
  }
}
