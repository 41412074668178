export class RegExPatterns {
  // Matches all-non numerical characters.
  public static readonly NonDigit = /[^0-9]/g;

  // Matches all-non numerical characters or period/decimal points.
  public static readonly NonDigitOrDecimal = /[^0-9.]/g;

  // Positive look ahead to find groupings of three digits (to apply numerical seperators)
  public static readonly FindNumericSeperator = /\B(?=(\d{3})+(?!\d))/g;

  public static readonly TaxIdRegex = new RegExp(/^\d{2}-\d{7}$/);
}
