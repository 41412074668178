<div class="page-title-container">
  <div
    class="page-title row g-0 d-flex justify-content-between align-items-center"
  >
    <div class="page-title-text fw-400" id="title">{{ pageTitle }}</div>
  </div>
</div>

<div class="instructionsList">
  <div *ngFor="let uploadInstruction of uploadInstructionsDisplay">
    <accordion
      [accordianInfo]="uploadInstruction.accordianInfo"
      [isSeperatorRequired]="false"
      class="row"
    >
      <div class="row rules" *ngFor="let rule of uploadInstruction.rules">
        {{ rule }}
      </div>

      <div class="row examples mt-2">
        <div class="p-0" *ngIf="uploadInstruction.examples!.length > 0">
          Example(s):
        </div>
        <ul class="ps-3">
          <li *ngFor="let example of uploadInstruction.examples">
            {{ example }}
          </li>
        </ul>
      </div>
    </accordion>
  </div>
</div>
