import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  catchError,
  lastValueFrom,
  map,
  of,
  throwError,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  address: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private httpClient: HttpClient) {}

  GetGoogleApiURL() {
    return this.httpClient.get('api/config-api/api-key');
  }

  GoogleCaptchaSiteKey(): Observable<any> {
    return this.httpClient.get<any>('api/config-api/captcha-api-key');
  }

  async GetGoogleTagManagerId() {
    return lastValueFrom(
      this.httpClient
        .get('api/config-api/google-tag-manager-id', { responseType: 'text' })
        .pipe(
          map((data: any) => {
            return data;
          }),
          catchError((error: any) => {
            if (error?.includes('SecretNotFound')) {
              return of(null);
            } else {
              return throwError(() => error);
            }
          }),
        ),
    );
  }
}
