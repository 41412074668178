import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  OnInit,
} from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss'],
})
export class FileDropComponent implements OnInit {
  @Input() uploadText = '';
  @Input() uploadGuideInfo = '';
  @Input() dragText = '';
  @Input() buttonClass = 'button button-gray';
  @Input() fileName = 'File to be uploaded';
  @Input() isUploadEnabled = false;
  @Input() isButtonDisabled = true;
  @Input() validFileExtension: any[] = [];
  @Output() EnableUploadOption: EventEmitter<any> = new EventEmitter<boolean>();
  @Output() fileObjectEmit: EventEmitter<any> = new EventEmitter<File>();
  @ViewChild('fileUpload') fileUpload!: ElementRef;
  fileObject: File | undefined; // Variable to store file
  messageDisplay = false;
  files: NgxFileDropEntry[] = [];
  acceptedFileExtensions = '';

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.acceptedFileExtensions = this.validFileExtension
      ?.map((ext: string) => '.' + ext)
      .join(',');
  }

  RemoveUploadedFile() {
    this.fileName = '';
    this.fileUpload.nativeElement.value = '';
    this.fileObject = undefined;
    this.messageDisplay = false;
    this.EnableUploadOption.emit(true);
    this.isButtonDisabled = true;
    this.ChangeButonClass();
  }

  ChangeButonClass() {
    this.buttonClass = this.isButtonDisabled
      ? 'button button-gray'
      : 'button button-dark';
  }

  Dropped(files: NgxFileDropEntry[]) {
    this.files = files;
    const droppedFile = files[0];
    // Is it a file?
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      this.fileName = fileEntry.name;
      if (this.IsValidFileExtension(this.fileName)) {
        fileEntry.file((file: File) => {
          this.fileObject = file;
          if (this.fileObject) {
            this.isButtonDisabled = false;
            this.EnableUploadOption.emit(false);
            this.fileObjectEmit.emit(this.fileObject);
            this.ChangeButonClass();
            this.cdr.detectChanges();
          }
        });
      }
    }
  }

  OnInputChange(event: any) {
    this.fileObject = event.target.files[0];
    if (this.fileObject) {
      this.fileName = this.fileObject.name;
      this.isButtonDisabled = false;
      this.fileObjectEmit.emit(this.fileObject);
      this.EnableUploadOption.emit(false);
      this.ChangeButonClass();
    }
  }

  IsValidFileExtension(fileName: string): boolean {
    let validExtension = false;
    const fileExtension = fileName.split('.').pop();
    if (
      fileExtension != null &&
      this.validFileExtension.includes(fileExtension)
    )
      validExtension = true;
    return validExtension;
  }
}
