import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EventTypes } from '../../toast/models/event-types';
import { MessagePanelEvent } from '../models/message-panel-event';

@Injectable({
  providedIn: 'root',
})
export class MessagePanelService {
  messageEvents: Observable<MessagePanelEvent>;
  _messageEvents = new BehaviorSubject<MessagePanelEvent>(
    {} as MessagePanelEvent,
  );

  constructor() {
    this.messageEvents = this._messageEvents.asObservable();
  }

  /**
   * Show success message notification.
   * @param messages Success message
   */
  ShowSuccessMessages(message: string) {
    this._messageEvents.next({
      type: EventTypes.Success,
      message,
    });
  }

  /**
   * Show info message notification.
   * @param messages Info message
   */
  ShowInfoMessages(message: string) {
    this._messageEvents.next({
      type: EventTypes.Info,
      message,
    });
  }

  /**
   * Show warning message notification.
   * @param messages Warning message
   */
  ShowWarningMessages(message: string) {
    this._messageEvents.next({
      type: EventTypes.Warning,
      message,
    });
  }

  /**
   * Show error messgae notification.
   * @param messages Error message
   */
  ShowErrorMessages(message: string) {
    this._messageEvents.next({
      type: EventTypes.Error,
      message,
    });
  }
}
