<div
  [ngClass]="
    !isUploadEnabled
      ? 'upload-file-zone d-flex upload-disabled'
      : 'upload-file-zone d-flex'
  "
>
  <div
    [ngClass]="
      !isButtonDisabled ? 'd-none' : 'upload-file-zone-child fw-400 text-center'
    "
  >
    <ngx-file-drop
      dropZoneLabel="Drag and Drop to upload the file here."
      (onFileDrop)="Dropped($event)"
      [multiple]="false"
      [disabled]="!isUploadEnabled"
      dropZoneClassName=""
      contentClassName="filedrop"
    >
      <ng-template
        ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector"
      >
        <div class="d-flex justify-content-center">
          <div *ngIf="!isUploadEnabled; else enabled">
            <img
              src="assets\icons\cloud_upload.svg"
              alt="Cloud Upload"
              class="d-block"
            />
          </div>
          <ng-template #enabled>
            <img
              src="assets\icons\cloud_upload_black.svg"
              alt="Cloud Upload black"
              class="d-block"
            />
          </ng-template>
        </div>
        <div [ngClass]="isUploadEnabled ? 'pt-1' : 'file-upload-text pt-1'">
          {{ dragText }}
        </div>
      </ng-template>
    </ngx-file-drop>
  </div>
  <div
    [ngClass]="!isButtonDisabled ? 'd-none' : 'vr upload-file-zone-vr'"
  ></div>

  <div
    [ngClass]="
      !isButtonDisabled
        ? 'd-none'
        : 'upload-file-zone-child text-center file-upload-text'
    "
  >
    <div [ngClass]="!isUploadEnabled ? 'upload-disabled' : ''">
      <label
        [ngClass]="
          !isUploadEnabled
            ? 'button button-light  d-inline-block upload-disabled button-gray'
            : 'button button-light  d-inline-block'
        "
      >
        <input
          type="file"
          (change)="OnInputChange($event)"
          accept="{{ acceptedFileExtensions }}"
          [disabled]="!isUploadEnabled"
          id="fileUpload"
          class="d-none"
          #fileUpload
        />
        {{ uploadText }}
      </label>
    </div>

    <div class="pt-1">
      {{ uploadGuideInfo }}
    </div>
  </div>

  <div
    [ngClass]="isButtonDisabled ? 'd-none' : 'd-flex justify-content-between'"
    style="width: 100%"
  >
    <div class="d-flex">
      <div class="file-success-img">
        <img src="assets\icons\upload-file.svg" alt="Cloud Upload" />
      </div>
      <span class="align-items-center fileName d-flex">{{ fileName }}</span>
    </div>
    <img
      role="button"
      class="close-blue"
      src="assets\icons\close-blank.svg"
      (click)="RemoveUploadedFile()"
      id="removeFile"
    />
  </div>
</div>
