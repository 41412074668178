import { Component, Input } from '@angular/core';

@Component({
  selector: 'rmp-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input() HeaderList: string[] = [];
  @Input() TableData: any[] = [];

  @Input() public showPaginator = true;
  @Input() public pageSize = 10;
  @Input() public currentPage = 0;
}
