import { Injectable } from '@angular/core';

import { UserDto } from 'src/app/models/UserDto';
import { UserService } from 'src/app/services/user.service';

const TOKEN_KEY = 'auth-token';
@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  constructor(private userService: UserService) {}

  ClearSession(): void {
    window.sessionStorage.clear();
  }

  public SaveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public GetToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public SaveUserToStorage(user: UserDto): void {
    this.userService.user$.next(user);
  }

  public ContainsClaim(claim: string): boolean {
    const token = this.GetToken();
    if (token) {
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));
      return tokenPayload[claim] !== undefined;
    }
    return false;
  }

  public GetClaimValueByKey(claim: string): string {
    const token = this.GetToken();
    if (token) {
      const tokenPayload = JSON.parse(atob(token.split('.')[1]));
      return tokenPayload[claim];
    }
    return '';
  }
}
