import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Editor, Toolbar } from 'ngx-editor';
import {
  RegistrationSummaryService,
  SummaryDetails,
} from '../services/registration-summary.service';
import { AlertService } from '../../common-components/alert/services/alert.service';
import { AlertEvent } from '../../common-components/alert/models/alert-event';
import { GeneralService } from 'src/app/services/general.service';

export interface FiTypes {
  displayName?: string;
}
export interface Versions {
  versionNumber?: number;
}

@Component({
  selector: 'registration-template',
  templateUrl: './registration-template.component.html',
  styleUrls: ['./registration-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationTemplateComponent implements OnInit, OnDestroy {
  isValid = true;
  summary = '';
  selectedFiType!: string;
  selectedVersion!: number;
  editor: Editor = new Editor();
  fiTypes!: FiTypes[];
  versions!: Versions[];
  errors: string[] = [];
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['link', 'image'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  constructor(
    private registrationSummaryService: RegistrationSummaryService,
    private summaryDetails: SummaryDetails,
    private cdr: ChangeDetectorRef,
    private alertService: AlertService,
    public generalService: GeneralService,
  ) {}

  ngOnInit(): void {
    this.GetFiTypes();
    this.versions = [];
    this.errors = [];
    this.selectedFiType = '0';
    this.selectedVersion = 0;
    this.summary = '';
    this.isValid = true;
    this.cdr.detectChanges();
  }

  ValidateSummary() {
    const parser = new DOMParser();
    const dom = parser.parseFromString(this.summary, 'text/html');
    const text = dom.body.innerText;
    const reg = new RegExp(/^[\sa-zA-Z0-9-.,:(){}]+$/);
    this.isValid = reg.test(text);
    if (text === '') {
      this.isValid = true;
    }
    this.errors = [];
    if (!this.isValid) {
      const msg = this.generalService?.GetErrorMessage(
        'REGSUMService_Summary_Should_Not_Contain',
      );
      this.errors.push(msg);
    }
  }

  GetFiTypes() {
    this.registrationSummaryService
      .GetFiTypesAsync()
      .then((response: any[]) => {
        this.fiTypes = response[0].items;
        this.selectedFiType = '0';
      });
  }

  GetVersions() {
    this.selectedVersion = 0;
    this.summary = '';
    this.registrationSummaryService
      .GetVersionsAsync(this.selectedFiType)
      .then((response: any[]) => {
        this.versions = response.map((item: number) => {
          return { versionNumber: item };
        });
      });
  }

  GetTemplateDetails() {
    this.errors = [];
    if (this.selectedVersion > 0) {
      this.registrationSummaryService
        .GetTemplateDetailsAsync(this.selectedFiType, this.selectedVersion)
        .then((response: any) => {
          this.summary = response.result;
          this.cdr.detectChanges();
        });
    } else {
      this.summary = '';
    }
  }

  SaveSummaryDetails() {
    this.errors = [];
    if (
      this.selectedFiType !== '0' &&
      this.summary !== '<p></p>' &&
      this.summary !== ''
    ) {
      this.summaryDetails = {
        summary: encodeURIComponent(this.summary),
        fiType: this.selectedFiType,
      };
      this.registrationSummaryService
        .SaveSummaryDetailsAsync(this.summaryDetails)
        .then(() => {
          const alertEvent: AlertEvent = {
            title: this.generalService?.GetErrorMessage(
              'REGSUMService_Registration_Summary_Title',
            ),
            message: `${
              this.selectedFiType
            } ${this.generalService?.GetErrorMessage(
              'REGSUMService_Template_Saved',
            )}`,
            confirmButtonText: 'Ok',
            cancelButtonText: '',
            showCancelButton: false,
          };
          this.alertService.ConfirmThis(alertEvent);
          this.ngOnInit();
        });
    } else {
      this.errors.push(
        this.generalService?.GetErrorMessage('REGSUMService_Please_Select_Fi'),
      );
    }
  }

  Save() {
    this.SaveSummaryDetails();
  }

  Cancel() {
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }
}
