export class Constants {
  public static readonly DateRangePlaceholderText = 'mm/dd/yyyy - mm/dd/yyyy';
  public static readonly TaxIdRegex = new RegExp(/^\d{2}-\d{7}$/);
  public static readonly ExcelExportKey = 'xlsx';
  public static readonly CsvExportKey = 'csv';
  public static readonly DocStatusOpen = 'opened';
  public static readonly DocStatusDownloadedByRecipient =
    'downloaded by recipient';
  public static readonly DocStatusDownloadedBySender = 'downloaded by sender';
  public static readonly IsReAuthenticatedClaim = 'isReAuthenticated';
}
