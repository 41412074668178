import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { WizardDto } from 'src/app/models/WizardDto';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { WizardService } from 'src/app/services/wizard.service';
import { ContactRegistryService } from 'src/app/services/contact-registry.service';
import { AlertService } from '../alert/services/alert.service';
import { AlertEvent } from '../alert/models/alert-event';
import { wizardSteps } from '../../contact-registry/models/AppConstants';

export type activeWizardObj = {
  id: number;
  name: string;
};

@Component({
  selector: 'wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnInit {
  @Input() wizardList?: WizardDto[];
  activeWizardObj: activeWizardObj = {} as activeWizardObj;
  activeWizard = 0;
  @Output() EmitWizarComponent = new EventEmitter<any>();
  @Output() SaveAndClose = new EventEmitter<any>();
  @Output() CancelClick = new EventEmitter<any>();
  isSubmitButtonEnabled = false;
  clickedButtonName = '';
  wizardSteps: any = wizardSteps;

  constructor(
    public utils: UtilitiesService,
    public wizardService: WizardService,
    public contactRegistryService: ContactRegistryService,
    private router: Router,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {
    if (this.wizardList) {
      this.activeWizardObj = this.wizardList[this.activeWizard];
      this.EmitComponent();
    }
    this.wizardService.activeWiWizardIndex = this.activeWizard;
    this.wizardService.isSubmitButtonEnabled.subscribe((res) => {
      this.isSubmitButtonEnabled = res;
    });
  }

  OnPrevClick() {
    if (this.activeWizard === 0) {
      return;
    }
    if (this.wizardList) {
      this.activeWizard =
        this.activeWizard < this.wizardList.length
          ? this.activeWizard - 1
          : this.activeWizard;
      this.activeWizardObj = this.wizardList[this.activeWizard];
      this.clickedButtonName = 'Previous';
      this.EmitComponent();
    }
    this.wizardService.activeWiWizardIndex = this.activeWizard;
    // keep next button enabled if click on previous button in and next step is valid
    if (
      this.contactRegistryService.creatContactDto[
        this.wizardService.activeWiWizardIndex
      ].data
    ) {
      this.wizardService.GetNextButtonDisabled(false);
    }
  }

  OnNextClick() {
    if (this.wizardList) {
      if (this.activeWizard === this.wizardList.length - 1) {
        return;
      }
      this.activeWizard =
        this.activeWizard < this.wizardList.length
          ? this.activeWizard + 1
          : this.activeWizard;
      this.activeWizardObj = this.wizardList[this.activeWizard];
      this.clickedButtonName = 'Next';
      this.EmitComponent();
      if (this.wizardService.activeWiWizardIndex === wizardSteps.stepZero) {
        this.wizardService.GetNextButtonDisabled(true);
      } else {
        this.wizardService.GetNextButtonDisabled(false);
      }
      this.wizardService.GetActiveWiWizardIndex(this.activeWizard);
    }
    if (this.wizardService.activeWiWizardIndex !== 3) {
      if (
        this.contactRegistryService.creatContactDto[
          this.wizardService.activeWiWizardIndex
        ].data
      ) {
        this.wizardService.GetNextButtonDisabled(false);
      }
    }
    this.contactRegistryService.isRTNSelected.next(true);
  }

  OnResetClick() {
    this.activeWizard = 0;
    if (this.wizardList) {
      this.activeWizardObj = this.wizardList[this.activeWizard];
      this.EmitComponent();
    }
  }

  EmitComponent() {
    this.EmitWizarComponent.emit({
      activeWizard: this.activeWizard,
      clickedButtonName: this.clickedButtonName,
    });
  }

  OnSaveAndCloseClick() {
    this.clickedButtonName = 'Save and Close';
    this.SaveAndClose.emit();
  }

  OnCancelClick() {
    this.clickedButtonName = 'Cancel';
    this.CancelClick.emit();
  }
}
