import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../modules/shared/shared.module';
import { CommonComponentsModule } from '../modules/common-components/common-components.module';
import { AppHeaderComponent } from './app-header.component';

@NgModule({
  declarations: [AppHeaderComponent],
  imports: [CommonModule, SharedModule, CommonComponentsModule],
  exports: [AppHeaderComponent],
})
export class AppHeaderModule {}
