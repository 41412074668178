import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'toLocalDate',
})
export class LocalDatePipe implements PipeTransform {
  transform(value: any): any {
    // Check if the input value is a valid date
    if (!value || !moment(value).isValid()) {
      return '-';
    }

    return moment.utc(value).local().format('MM/DD/YYYY, hh:mm A zzz');
  }
}
