import { ChangeDetectorRef, Component } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { FAQSectionDto } from './models/FAQSectionDto';
import { accordianDto } from '../common-components/accordion/accordion.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent {
  faqSections: FAQSectionDto[] = [];
  accordionDtos: accordianDto[] = [];

  title = 'Risk Management Portal - FAQs';
  faqSectionHtmlId: string | null = null;

  constructor(
    private readonly generalService: GeneralService,
    private readonly cdr: ChangeDetectorRef,
    private readonly route: ActivatedRoute,
  ) {
    this.faqSectionHtmlId = this.route?.snapshot?.queryParamMap?.get('id');
  }

  async ngOnInit() {
    await this.generalService.GetFAQsAsync().then((data: FAQSectionDto[]) => {
      this.faqSections = data;
      this.createAccordionDtos();
      this.cdr.detectChanges();
    });
  }

  getFaqSection(faqSection: FAQSectionDto): any {
    return this.accordionDtos.find((section) => section.id === faqSection.id);
  }

  /**
   * Generates accordion DTOs that are consumed by the accordion component.
   */
  private createAccordionDtos(): void {
    this.faqSections.forEach((section: FAQSectionDto) => {
      const showHideClass = this.getAccordionCssClass(section);

      const accordionDto: accordianDto = {
        accordianSubheading: undefined,
        name: section.title,
        collapse: false,
        id: section.id,
        class: showHideClass,
        isHeaderClassRequired: true,
        isSecondaryDisabled: false,
        primaryContactList: [],
        secondaryContactList: [],
      };

      this.accordionDtos.push(accordionDto);
    });
  }

  /**
   * Determines whether an accordion should be expanded or collapsed based on query parameter.
   * @param section
   * @returns 'show' or 'hide' class for the accordion component.
   */
  private getAccordionCssClass(section: FAQSectionDto): string {
    return this.faqSectionHtmlId === null ||
      // If there is an HTML ID in the URL parameter but it isn't a valid option, show expanded accordions
      !this.faqSections.some((s) => s.htmlId === this.faqSectionHtmlId) ||
      section.htmlId === this.faqSectionHtmlId
      ? 'show'
      : 'hide';
  }
}
