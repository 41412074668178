import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import { ToastEvent } from './models/toast-event';
import { EventTypes } from './models/event-types';
import { fromEvent, take } from 'rxjs';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit, AfterViewInit {
  currentToasts: ToastEvent[] = [];
  @Input() type!: EventTypes;
  @Input() title!: string;
  @Input() message!: string;
  @Output() disposeEvent = new EventEmitter();
  @ViewChild('toastElement', { static: true }) toastEl!: ElementRef;

  ngOnInit() {
    fromEvent(this.toastEl.nativeElement, 'hidden.bs.toast')
      .pipe(take(1))
      .subscribe(() => this.Hide());
  }

  ngAfterViewInit() {
    this.toastEl.nativeElement.classList.add('show');
    setTimeout(() => {
      this.Hide();
    }, 5000);
  }

  Hide() {
    this.disposeEvent.emit();
  }
}
