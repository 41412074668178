<div class="modal" tabindex="-1" *ngIf="message" ngClass="display">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div
        class="modal-header bgcolor d-flex justify-content-between align-items-center"
      >
        <h1 class="modal-title fs-5 fw-700">{{ message.title }}</h1>
        <img
          src="./assets/icons/close-white.svg"
          class="cp"
          alt=""
          (click)="message.Cancel()"
        />
      </div>
      <div class="modal-body">
        <div
          class="d-flex align-items-start alert-icon"
          [innerHTML]="message.message"
        ></div>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button
          *ngIf="message.showPrintButton"
          type="button"
          class="button button-light"
          (click)="message.Print()"
        >
          <img src="./assets/icons/print.svg" alt="" />
          {{ message.printButtonText }}
        </button>
        <button
          *ngIf="message.showCancelButton"
          type="button"
          class="button button-light alertbtnwidth"
          (click)="message.Cancel()"
        >
          {{ message.cancelButtonText }}
        </button>
        <button
          type="button"
          class="button button-dark alertbtnwidth"
          (click)="message.Confirm()"
        >
          {{ message.confirmButtonText }}
        </button>
      </div>
    </div>
  </div>
</div>
