<div class="page-title-container">
  <div
    class="page-title row d-flex justify-content-between align-items-center g-0"
  >
    <div class="page-title-text col-md-8 fw-400">
      Registration Summary Template
    </div>
    <div class="d-flex col-md-4 justify-content-end">
      <button class="button button-dark" [disabled]="!isValid" (click)="Save()">
        Save
      </button>
      <button class="button button-light ml-10" (click)="Cancel()">
        Cancel
      </button>
    </div>
  </div>
</div>
<div class="container row col-10 mt-3">
  <div class="text-danger" *ngIf="errors.length > 0">
    <label *ngFor="let error of errors">{{ error }}</label>
  </div>
  <div class="content">
    <div>
      <div class="row">
        <div class="n-input-group col-6">
          <b>FI</b>
          <select
            class="n-form-control form-select-sm"
            [(ngModel)]="selectedFiType"
            (change)="GetVersions()"
          >
            <option [value]="0" [selected]="true">--Select--</option>
            <option *ngFor="let fi of fiTypes" value="{{ fi.displayName }}">
              {{ fi.displayName }}
            </option>
          </select>
        </div>
        <div class="n-input-group col-6">
          <b>Version</b>
          <select
            class="n-form-control form-select-sm"
            [(ngModel)]="selectedVersion"
            (change)="GetTemplateDetails()"
          >
            <option [value]="0" [selected]="true">--Select--</option>
            <option
              *ngFor="let version of versions"
              value="{{ version.versionNumber }}"
            >
              {{ version.versionNumber }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <br />
    <b>Summary</b>
    <div class="editor" [ngClass]="isValid ? '' : 'alert alert-danger'">
      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"> </ngx-editor-menu>
      <ngx-editor
        [editor]="editor"
        [(ngModel)]="summary"
        [disabled]="false"
        [placeholder]="'Type here...'"
        (ngModelChange)="ValidateSummary()"
      >
      </ngx-editor>
    </div>
    <br />
  </div>
</div>
<br />
