import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[preventSpace]',
})
export class PreventSpaceDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: any) {
    const e = <any>event;
    const val = e.target.value;
    if (val.length === 0 && e.code === 'Space') {
      e.preventDefault();
      return;
    }
  }
}
