import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError, switchMap } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { AuthenticationService } from '../authentication/authentication.service';
import { TokenStorageService } from '../authentication/token-storage.service';
import { Router } from '@angular/router';
import { MessagePanelService } from 'src/app/modules/common-components/message-panel/services/message-panel.service';
@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthenticationService,
    private tokenService: TokenStorageService,
    private router: Router,
    private messagePanelService: MessagePanelService,
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const endpointsWithNoAuthorization = ['api/config-api/'];
    let token: string | null = '';
    if (
      !endpointsWithNoAuthorization.some((url) => request.url.startsWith(url))
    ) {
      token = this.tokenService.GetToken();
      if (token && !request.headers.get('Authorization')) {
        // Change with care. This handles all the http requests

        if (request.headers.get('Content-Type') == 'multipart/form-data') {
          request = request.clone({
            headers: request.headers.delete(
              'Content-Type',
              'multipart/form-data',
            ),
          });
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
              'Client-TimeZone':
                Intl.DateTimeFormat().resolvedOptions().timeZone,
              'X-Frame-Options': 'SAMEORIGIN',
              'Cache-Control': 'public',
              'max-age': '31536000',
              'X-Content-Type-Options': 'nosniff',
              'Content-Security-Policy':
                "script-src 'self'; style-src 'self'; img-src 'self'",
              'Strict-Transport-Security':
                'max-age=31536000; includeSubDomains',
            },
          });
        } else {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
              'Content-Type':
                request.headers.get('Content-Type') || 'application/json',
              'Client-TimeZone':
                Intl.DateTimeFormat().resolvedOptions().timeZone,
              'X-Frame-Options': 'SAMEORIGIN',
              'Cache-Control': 'public',
              'max-age': '31536000',
              'X-Content-Type-Options': 'nosniff',
              'Content-Security-Policy':
                "script-src 'self'; style-src 'self'; img-src 'self'",
              'Strict-Transport-Security':
                'max-age=31536000; includeSubDomains',
            },
          });
        }
      }
    }

    return next.handle(request).pipe(
      catchError((err) => {
        // create array of error codes to allow globally
        const allowErrorCodes = ['FileNotValid', 'ValidationException'];

        // check if error code is in the allowed list
        // if it is, return the error
        if (allowErrorCodes.includes(err?.error?.ErrorCode)) {
          err?.error?.ErrorMessages?.forEach((message: string) => {
            this.messagePanelService.ShowErrorMessages(
              message.trim().replaceAll("'", ''),
            );
          });
          return throwError(() => err?.error);
        }

        if (err.status === 401) {
          // Try to refresh token
          return this.authService.RefreshToken(this.router.url).pipe(
            switchMap(() => {
              // After successful token refresh, retry the original request
              token = this.tokenService.GetToken();

              // Clone the original request with the new token
              const newRequest = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${token}`,
                },
              });

              // Retry the request
              return next.handle(newRequest);
            }),
            catchError((refreshError) => {
              this.authService.ExternalLogout();
              this.router.navigate(['login']);
              return throwError(() => err.error);
            }),
          );
        } else if (err.status === 403) {
          this.authService.ExternalLogout();
        } else {
          this.messagePanelService.ShowErrorMessages(
            'An error occured. Please try again later.',
          );
        }
        return throwError(() => err?.error);
      }),
    );
  }
}
