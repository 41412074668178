<auth-header></auth-header>
<page-title pageTitle="{{ title }}"></page-title>
<div class="container">
  <div class="row">
    <div class="col-12">
      <div *ngFor="let section of faqSections">
        <accordion
          [accordianInfo]="getFaqSection(section)"
          [isSeperatorRequired]="false"
          class="row"
        >
          <div *ngFor="let question of section.questions">
            <div class="q-and-a">
              <div class="question">
                {{ question.question }}
              </div>
              <div [innerHtml]="question.answer"></div>
            </div>
          </div>
        </accordion>
      </div>
    </div>
  </div>
</div>
<div class="fixed-faq-footer">
  <app-footer [showSupportNumber]="false"></app-footer>
</div>
