import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'invalid-phone-number-msg',
  templateUrl: './invalid-phone-number-msg.component.html',
  styleUrls: ['./invalid-phone-number-msg.component.scss'],
})
export class InvalidPhoneNumberMsgComponent {
  @Input() class = '';
  @Output() EmitPhoneMsg = new EventEmitter();

  ToggleMsg() {
    this.EmitPhoneMsg.emit();
  }
}
