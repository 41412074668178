import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private isLoading = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoading.asObservable();

  Show(): void {
    this.isLoading.next(true);
  }

  Hide(): void {
    setTimeout(() => {
      this.isLoading.next(false);
    }, 500);
  }
}
