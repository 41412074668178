import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { formatDate } from '@angular/common';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { RegistrationSummaryService } from '../services/registration-summary.service';
import { AutoCompleteDto } from 'src/app/models/AutoCompleteDto';
import { SearchRequestDto } from '../../contact-registry/models/SearchRequestDto';
import { ContactRegistryService } from 'src/app/services/contact-registry.service';
import { UserService } from 'src/app/services/user.service';
import { SpinnerService } from '../../common-components/spinner/service/spinner.service';

@Component({
  selector: 'registration-details',
  templateUrl: './registration-details.component.html',
  styleUrls: ['./registration-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegistrationDetailsComponent implements OnInit {
  addressLine1 = '2550 Wasser Terrace • Suite 400 • Herndon, VA 20171';
  addressLine2 = '703-349-4556 • rmportal@nacha.org';
  addressLine3 = 'Nacha. All rights reserved';
  year = new Date().getFullYear();
  @ViewChild('printSummary', { static: false }) printSummary!: ElementRef;
  @ViewChild('summaryDetails', { static: false }) summaryDetails!: ElementRef;
  searchRequest: SearchRequestDto = {};
  autoCompleteList: AutoCompleteDto[] = [];
  showSummary = false;
  routingNumber = '';

  constructor(
    private registrationSummaryService: RegistrationSummaryService,
    public contactRegistryService: ContactRegistryService,
    private cdr: ChangeDetectorRef,
    public userService: UserService,
    private spinnerService: SpinnerService,
  ) {}

  ngOnInit(): void {
    if (!this.userService.isNachaAdmin() && !this.userService.isNachaUser()) {
      this.registrationSummaryService
        .GetSummaryDetailsAsync('')
        .then((response: any) => {
          this.showSummary = true;
          this.cdr.detectChanges();
          this.summaryDetails.nativeElement.innerHTML =
            response.result.replaceAll('<p></p>', '<br>');
        });
    }
  }

  DownloadPdf() {
    html2canvas(this.printSummary.nativeElement).then((canvas) => {
      const fileWidth = 180;
      const fileHeight = (canvas.height * fileWidth) / canvas.width;
      const fileURI = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const position = 12;
      pdf.addImage(fileURI, 'PNG', 15, position, fileWidth, fileHeight);
      const fileName =
        'RegistrationSummary-' +
        formatDate(Date.now(), 'yyyyMMddhhmmss', 'en-US') +
        '.pdf';
      pdf.save(fileName);
    });
  }

  PrintPdf() {
    this.spinnerService.Show();
    html2canvas(this.printSummary.nativeElement).then((canvas) => {
      const fileWidth = 180;
      const fileHeight = (canvas.height * fileWidth) / canvas.width;
      const fileURI = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const position = 12;
      pdf.addImage(fileURI, 'PNG', 15, position, fileWidth, fileHeight);
      const hiddenFrame = document.createElement('iframe');
      hiddenFrame.style.position = 'fixed';
      hiddenFrame.style.visibility = 'hidden';
      hiddenFrame.src = URL.createObjectURL(pdf.output('blob'));
      document.body.appendChild(hiddenFrame);
      hiddenFrame.onload = () => {
        const handleEvent = () => {
          window.removeEventListener('focus', handleEvent);
          document.body.removeChild(hiddenFrame);
        };
        window.addEventListener('focus', handleEvent);
        hiddenFrame.contentWindow?.print();
      };
      this.spinnerService.Hide();
    });
  }

  OnSearchData(event: any) {
    this.routingNumber =
      event[0]?.filterValue != null ? event[0]?.filterValue : '';
    if (this.routingNumber !== '') {
      this.registrationSummaryService
        .GetSummaryDetailsAsync(this.routingNumber)
        .then((response: any) => {
          this.showSummary = true;
          this.cdr.detectChanges();
          this.summaryDetails.nativeElement.innerHTML =
            response.result.replaceAll('<p></p>', '<br>');
        });
    }
  }

  async OnAutoCompleteData(data: any) {
    this.autoCompleteList = data;
  }
}
