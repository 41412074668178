<div class="row wizard-container wizard-stepwrapper">
  <div class="col-md-7">
    <div class="wizard-header-container d-flex align-itens-center">
      <h1 class="fw-300">Create Contact/User</h1>
    </div>
  </div>
  <div class="col-md-5 d-flex justify-content-end">
    <div class="wizard-action-container d-flex align-items-center">
      <div class="wizard-left-actions d-flex">
        <div
          class="action-btn-container"
          *ngIf="activeWizard > wizardSteps.stepZero"
        >
          <button-component
            [buttonClass]="'button button-dark btnImgLeft'"
            [isLeftImg]="true"
            [isRightImg]="false"
            [buttonImage]="'single-arrow-left-light.png'"
            Text="Previous"
            (OnClick)="OnPrevClick()"
            [isButtonDisabled]="false"
          ></button-component>
        </div>
        <div
          class="action-btn-container"
          *ngIf="
            (activeWizard > wizardSteps.stepZero ||
              contactRegistryService.creatContactDto[0].data) &&
            activeWizard !== wizardSteps.stepTwo
          "
        >
          <button-component
            [buttonClass]="
              !wizardService.isNextButtonDisabled
                ? 'button button-dark btnImgRight flex-row-reverse'
                : 'button button-gray btnImgRight flex-row-reverse'
            "
            [isLeftImg]="false"
            [isRightImg]="true"
            [buttonImage]="
              !wizardService.isNextButtonDisabled
                ? 'single-arrow-right-light.png'
                : 'single-arrow-right-dark.png'
            "
            Text="Next"
            (OnClick)="OnNextClick()"
            [isButtonDisabled]="wizardService.isNextButtonDisabled"
          ></button-component>
        </div>
        <div
          class="img-seperator"
          *ngIf="
            activeWizard > wizardSteps.stepZero ||
            contactRegistryService.creatContactDto[0].data
          "
        >
          <img src="./assets/icons/pipe-line.png" alt="" />
        </div>
        <div class="wizard-left-actions d-flex">
          <div
            class="action-btn-container"
            *ngIf="
              activeWizard > wizardSteps.stepZero ||
              contactRegistryService.creatContactDto[0].data
            "
          >
            <button-component
              [buttonClass]="
                wizardService.activeWiWizardIndex !== wizardSteps.stepTwo ||
                !isSubmitButtonEnabled
                  ? 'button button-gray'
                  : 'button button-dark'
              "
              [isLeftImg]="false"
              [isRightImg]="false"
              Text="Submit"
              [isButtonDisabled]="
                wizardService.activeWiWizardIndex !== wizardSteps.stepTwo ||
                !isSubmitButtonEnabled
              "
              (OnClick)="OnSaveAndCloseClick()"
            ></button-component>
          </div>
          <div class="action-btn-container">
            <button-component
              [buttonClass]="'button button-light'"
              [isLeftImg]="false"
              [isRightImg]="false"
              Text="Cancel"
              [isButtonDisabled]="false"
              (OnClick)="OnCancelClick()"
            ></button-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row wizard-container">
  <div class="col-12">
    <div class="wizard-step-container d-flex align-items-center">
      <div
        class="wizard-steps d-flex align-items-center"
        *ngFor="let w of wizardList; let i = index"
      >
        <div
          class="wizard-step-box d-flex justify-content-center align-items-center"
          [ngClass]="[
            i === wizardService.activeWiWizardIndex
              ? 'active-wizard-box'
              : 'inactive-wizard-box',
            i < wizardService.activeWiWizardIndex
              ? 'wizard-completed-box'
              : 'inactive-wizard-box'
          ]"
        >
          <span
            *ngIf="i >= wizardService.activeWiWizardIndex"
            [ngClass]="
              i === wizardService.activeWiWizardIndex
                ? 'active-wizard-txt'
                : 'inactive-wizard-txt'
            "
            >{{ i + 1 }}</span
          >
          <img
            *ngIf="i < wizardService.activeWiWizardIndex"
            src="./assets/icons/check_circle-success.svg"
            alt=""
          />
        </div>
        <div
          class="wizard-step-txt d-flex align-items-center"
          [ngClass]="
            i + 1 === activeWizardObj.id
              ? 'active-wizard-txt'
              : 'inactive-wizard-txt'
          "
          [ngStyle]="{
            color:
              i === wizardService.activeWiWizardIndex ? '#2F6CF6' : '#000000'
          }"
        >
          <span>{{ w.name }}</span>
          <span class="wizard-line" *ngIf="i !== 2"></span>
        </div>
        <div
          *ngIf="i === wizardService.activeWiWizardIndex"
          [ngStyle]="{
            width:
              wizardService.activeWiWizardIndex < wizardList!.length - 1
                ? 'calc(100% - 80px)'
                : '100%'
          }"
          class="active-wizard-border"
        ></div>
      </div>
    </div>
  </div>
</div>
