import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/login/login/login.component';
import { AuthGuard } from 'src/app/infrastructure/guards/auth-guard.service';
import { UploadInstructionsComponent } from './modules/common-components/upload-instructions/upload-instructions.component';
import { LogoutComponent } from './log-out/log-out.component';
import { FaqComponent } from './modules/faq/faq.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
    pathMatch: 'full',
  },
  { path: 'redirect', component: LoginComponent, pathMatch: 'full' },
  { path: 'log-out', component: LogoutComponent, pathMatch: 'full' },
  {
    path: 'faq',
    loadChildren: () =>
      import('./modules/faq/faq.module').then((m) => m.FaqModule),
    component: FaqComponent,
    pathMatch: 'full',
  },
  {
    path: 'instructions',
    component: UploadInstructionsComponent,
    pathMatch: 'full',
  },
  {
    path: 'ach-contact-registry',
    loadChildren: () =>
      import('./modules/contact-registry/contact-registry.module').then(
        (m) => m.ContactRegistryModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'registration-summary',
    loadChildren: () =>
      import('./modules/registration-summary/registration-summary.module').then(
        (m) => m.RegistrationSummaryModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'management',
    loadChildren: () =>
      import('./modules/management/management.module').then(
        (m) => m.ManagementModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'tps',
    loadChildren: () =>
      import('./modules/tps/tps.module').then((m) => m.TpsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'tod',
    loadChildren: () =>
      import('./modules/tod/tod.module').then((m) => m.TodModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'suspended-originator',
    loadChildren: () =>
      import('./modules/suspended-originator/suspended-originator.module').then(
        (m) => m.SuspendedOriginatorModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'accquity-management',
    loadChildren: () =>
      import('./modules/accquity-management/accquity-management.module').then(
        (m) => m.AccquityManagementModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('./modules/audit-logs/audit-logs.module').then(
        (m) => m.AuditLogsModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'user-management',
    loadChildren: () =>
      import('./modules/user-management/user-management.module').then(
        (m) => m.UserManagementModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'pending-approvals',
    loadChildren: () =>
      import('./modules/pending-approvals/pending-approvals.module').then(
        (m) => m.PendingApprovalsModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'direct-access',
    loadChildren: () =>
      import('./modules/direct-access/direct-access.module').then(
        (m) => m.DirectAccessModule,
      ),
    canActivate: [AuthGuard],
  },
  // { path: 'login', component: LoginComponent, pathMatch: 'full' },
  {
    path: 'portal-registration',
    loadChildren: () =>
      import('./modules/portal-registration/portal-registration.module').then(
        (m) => m.PortalRegistrationModule,
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./modules/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'secure-exchange',
    loadChildren: () =>
      import('./modules/secure-exchange/secure-exchange.module').then(
        (m) => m.SecureExchangeModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'portal-settings',
    loadChildren: () =>
      import('./modules/portal-settings/portal-settings.module').then(
        (m) => m.PortalSettingsModule,
      ),
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
