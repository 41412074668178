import { Pipe, PipeTransform } from '@angular/core';
import { RegExPatterns } from '../models/RegExPatterns';

@Pipe({
  name: 'commaSeparatedNumber',
})
export class CommaSeparatedNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || isNaN(Number(value))) {
      return value;
    }

    const parts = value.split('.');
    const integerPart = parts[0].replace(RegExPatterns.NonDigit, '');

    // Add commas every three digits in the integer part
    const formattedInteger = integerPart.replace(
      RegExPatterns.FindNumericSeperator,
      ',',
    );

    // If there is a decimal part, append it to the formatted integer part
    return parts.length > 1
      ? `${formattedInteger}.${parts[1]}`
      : formattedInteger;
  }
}
