import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RegistrationSummaryComponent } from '../registration-summary.component';
import { RegistrationTemplateComponent } from '../registration-template/registration-template.component';
import { RegistrationDetailsComponent } from '../registration-details/registration-details.component';
import { RegistrationDetailsAuthGuard } from '../guards/registration-details-authguard';
import { RegistrationTemplateAuthGuard } from '../guards/registration-template-authguard';

const routes: Routes = [
  {
    path: '',
    component: RegistrationSummaryComponent,
    children: [
      {
        path: 'details',
        component: RegistrationDetailsComponent,
        canActivate: [RegistrationDetailsAuthGuard],
      },
      {
        path: 'template',
        component: RegistrationTemplateComponent,
        canActivate: [RegistrationTemplateAuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RegistrationSummaryRoutingModule {}
