<div class="n-input-group" [formGroup]="FormGroup">
  <label [for]="Id" class="whr-wrap">{{ Label }}</label>
  <p-dropdown
    [options]="timeString"
    [formControlName]="Name"
    [id]="Id"
    optionValue="value"
    optionLabel="label"
    placeholder="HH:MM AM"
    styleClass="drop-down-filter"
    dropdownIcon="time-picker-icon"
  ></p-dropdown>
  <div class="error-container">
    <span
      class="error"
      *ngIf="
        (IsSubmitted && FormGroup.get(Name)?.hasError('required')) ||
        (FormGroup.controls[Name].dirty &&
          FormGroup.get(Name)?.hasError('required'))
      "
      >{{ Label }} is required.</span
    >
  </div>
</div>
