import { DatePick } from './DatePickDto';

export interface BaseFilterDto {
  filterType: string;
  filterPropertyName?: string;
  filterMode: FilterMode;
}

export interface FilterDto extends BaseFilterDto {
  isSelectAll?: boolean;
  allChecked?: boolean;
  placeHolderValue?: string;
  matchModeOptions: string[];
  dateTypeOptions?: DatePick[];
  matchModeValue?: string;
  filterInputValue?: string;
  selectedDateTypeValue?: DatePick;
  dateRange?: Date[];
  filters: Categories[];
  seperatorRequired: boolean;
  radioOptions?: RadioOption[];
  selectedRadioValue?: any;
}

export interface FilterResponseDto extends BaseFilterDto {
  filterMatchModeValue?: string;
  filterValue?: string;
  filterSelectedDateType?: DatePick;
  filterDateValue?: Date[];
}

export interface RadioOption {
  label: string;
  value: any;
}

export enum FilterMode {
  checkbox = 'checkbox',
  primeDropBox = 'primeDropBox',
  dateFilter = 'dateFilter',
  selectList = 'selectList',
  radio = 'radio',
}

export interface Categories {
  name?: string;
  count?: number;
  checked?: boolean;
}
