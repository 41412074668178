import { Injectable } from '@angular/core';
import { UpdateContactDetailsDto } from '../modules/contact-registry/models/UpdateContactDetailsDto';
import { ContactListDto } from '../modules/contact-registry/models/ContactListDto';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchRequestDto } from '../modules/contact-registry/models/SearchRequestDto';
import { CreateContactDetailsDto } from '../modules/contact-registry/models/CreateContactDetailsDto';
import {
  ContactRegistryCollectionResultDto,
  FilterCriteria,
} from '../modules/contact-registry/models/ContactRegistryCollectionResult';
import { CustomHttpClientService } from 'src/app/infrastructure/services/custom-http-client.service';
import { ContactNoteDto } from '../modules/contact-registry/models/ContactNoteDto';
import { ACHFlowSuccessDto } from '../modules/contact-registry/models/ACHFlowSuccessDto';
import { FileResponse } from '../models/FileResponse';
import { FileService } from '../infrastructure/services/file.service';
import { FiComplianceDto } from '../models/FiComplianceDto';
import { RoutingSearchCriteriaDto } from '../models/RoutingSearchCriteriaDto';
import { PendingACHContactApprovalFilter } from '../modules/pending-approvals/models/PendingACHContactApprovalFilter';

@Injectable({
  providedIn: 'root',
})
export class ContactRegistryService {
  private contactDetailData: BehaviorSubject<ContactListDto> =
    new BehaviorSubject<ContactListDto>({} as ContactListDto);
  currentContactDetailData = this.contactDetailData.asObservable();
  contactDetailDataEmit: ContactListDto = {} as ContactListDto;

  contactList: ContactListDto[] = [];
  contactListData: BehaviorSubject<ContactListDto> = new BehaviorSubject<any>(
    this.contactList,
  );
  contactListDataData = this.contactListData.asObservable();

  // active wizard behaviour subject
  activeWizard = 'Routing Number';
  private activeWizardInitial: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.activeWizard);
  activeWizardData = this.activeWizardInitial.asObservable();

  // accordian index
  accordianInex!: number;
  private accordianInexInitial: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.accordianInex,
  );
  accordianInexData = this.accordianInexInitial.asObservable();

  // ACH categories
  accordianList: any[] = [];
  private accordianListInitial: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.accordianList,
  );
  accordianListData = this.accordianListInitial.asObservable();

  // ACH categories contact type
  contactType!: string;
  private contactTypeInitial: BehaviorSubject<string> =
    new BehaviorSubject<string>(this.contactType);
  contactTypeData = this.contactTypeInitial.asObservable();

  // Secondary rtns
  secondaryRtns: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([
    'jhl',
  ]);

  // selected routing number data from create contact step
  selectedRoutingNumber!: any;
  selectedRoutingNumberInitial: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.selectedRoutingNumber,
  );
  selectedRoutingNumberData = this.selectedRoutingNumberInitial.asObservable();

  // create contact selected data
  creatContactDto: any[] = [
    { stepName: 'routingNumber', data: null },
    { stepName: 'mandatoryContact', data: null },
    { stepName: 'optionalCategory', data: null },
  ];

  creatContactDtoInitial: BehaviorSubject<any> = new BehaviorSubject<any>(
    this.creatContactDto,
  );
  creatContactDtoData = this.creatContactDtoInitial.asObservable();

  addedContactCount: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  savedFromModalActiveType: BehaviorSubject<string> =
    new BehaviorSubject<string>('none');

  successMsgAfterACHFlowSaveDTO: BehaviorSubject<ACHFlowSuccessDto> =
    new BehaviorSubject<ACHFlowSuccessDto>({ isMsgActive: false, message: '' });
  isACHFlowEditModeOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  isRTNSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: CustomHttpClientService,
    private fileService: FileService,
  ) {}

  SetContactDetailData(data: ContactListDto) {
    this.contactDetailData.next(data);
    this.contactDetailData.subscribe((res: ContactListDto) => {
      this.contactDetailDataEmit = res;
    });
  }

  TriggerContactList(data: any) {
    this.contactListData.next(data);
    this.contactList = data;
    return data;
  }

  GetActiveWizard(data: any) {
    this.activeWizardInitial.next(data);
    this.activeWizard = data;
    return data;
  }

  GetCategories(data: any) {
    this.accordianListInitial.next(data);
    this.accordianList = data;
    return data;
  }

  GetAccordianIndex(data: any) {
    this.accordianInexInitial.next(data);
    this.accordianInex = data;
    return data;
  }

  GetAccordianContactType(data: any) {
    this.contactTypeInitial.next(data);
    this.contactType = data;
    return data;
  }

  TriggerContactRegistrySelectedData(data: any) {
    this.creatContactDtoInitial.next(data);
    this.creatContactDto = data;
    return data;
  }

  async GetContactListAsync(
    searchRequest: SearchRequestDto,
    skip: number,
    take: number,
    includeFilters: boolean,
  ): Promise<ContactRegistryCollectionResultDto> {
    return lastValueFrom(
      this.httpClient
        .post<ContactRegistryCollectionResultDto>(
          `api/contact-registry/contacts/search?skip=${skip}&take=${take}&includeFilters=${includeFilters}`,
          searchRequest,
        )
        .pipe(map((data: ContactRegistryCollectionResultDto) => data)),
    );
  }

  async GetFilterListAsync(
    searchRequest: SearchRequestDto,
  ): Promise<FilterCriteria[]> {
    return lastValueFrom(
      this.httpClient
        .post<
          FilterCriteria[]
        >(`api/contact-registry/contacts/filters`, searchRequest, false)
        .pipe(map((data: FilterCriteria[]) => data)),
    );
  }

  async ExportContactsAsync(
    searchRequest: SearchRequestDto,
  ): Promise<FileResponse> {
    const url = `api/contact-registry/export-contacts`;
    return lastValueFrom(this.fileService.GetFileResponse(url));
  }

  async ExportPendingApprovalContactsAsync(
    filters: PendingACHContactApprovalFilter,
  ): Promise<FileResponse> {
    const url = `api/pending-approval/export-contacts`;
    return lastValueFrom(
      this.fileService.GetFileResponseWithInput(url, filters),
    );
  }

  async CreateContactDetailsAsync(
    createContactDetailsDto: CreateContactDetailsDto,
  ) {
    return lastValueFrom(
      this.httpClient
        .post(`api/contact-registry/contacts`, createContactDetailsDto)
        .pipe(map((data: any) => data)),
    );
  }

  async UpdateContactDetailsAsync(
    updateContactDetailsDto: UpdateContactDetailsDto,
  ) {
    return lastValueFrom(
      this.httpClient
        .put(`api/contact-registry/contacts`, updateContactDetailsDto)
        .pipe(map((data: any) => data)),
    );
  }

  async GetAddedContactListAsync() {
    return lastValueFrom(
      this.httpClient
        .get('api/contact-registry/contacts/draft')
        .pipe(map((data: any) => data)),
    );
  }

  GetMandatoryAndOptionalCategories() {
    return this.httpClient
      .get(
        'api/select-list/FIOptionalContactCategory,FIMandatoryContactCategory',
        true,
      )
      .pipe(map((data: any) => data));
  }

  async GetAddedContactListUpdated(fiId: number, isOdfi: boolean) {
    return lastValueFrom(
      this.httpClient
        .get(
          'api/contact-registry/contacts/details?fiId=' +
            fiId +
            '&isOdfi=' +
            isOdfi,
          true,
        )
        .pipe(map((data: any) => data)),
    );
  }

  async SaveACHContactFlowData(formData: any, fiId: number, isOdfi: boolean) {
    return lastValueFrom(
      this.httpClient
        .post(
          `api/contact-registry/contacts/complete-save?fiId=` +
            fiId +
            '&isOdfi=' +
            isOdfi,
          formData,
        )
        .pipe(map((data: any) => data)),
    );
  }

  async GetRoutingNumberListAsync(searchCriteria: RoutingSearchCriteriaDto) {
    return this.httpClient.post(`api/fi/details`, searchCriteria);
  }

  async ValidateTelephoneNumberAsync(phoneNumber: string) {
    return lastValueFrom(
      this.httpClient
        .get(`api/validate/phonenumber?phoneNumber=${phoneNumber}`)
        .pipe(map((data: any) => data)),
    );
  }

  async UpdateContactStatusAsync(status: string, contactId: number) {
    return lastValueFrom(
      this.httpClient
        .patch(
          `api/contact-registry/contacts/${contactId}/status`,
          `"${status}"`,
        )
        .pipe(map((data: any) => data)),
    );
  }

  async GetNotesByFiIdAsync(fiId: number, isOdfi: boolean) {
    return lastValueFrom(
      this.httpClient.get(`api/fi/${fiId}/notes?isOdfi=${isOdfi}`),
    );
  }

  async CreateContactNotesAsync(
    contactNote: ContactNoteDto,
  ): Promise<ContactNoteDto[]> {
    return lastValueFrom(
      this.httpClient
        .post(`api/fi/${contactNote.fiId}/note`, contactNote)
        .pipe(map((data: any) => data)),
    );
  }

  async GetFiOutOfComplianceMessageFiIdAsync(
    fiId: number,
    fiType: string,
  ): Promise<FiComplianceDto> {
    return lastValueFrom(
      this.httpClient
        .get<FiComplianceDto>(
          `api/fi/compliance-message?fiId=${fiId}&fiType=${fiType}`,
        )
        .pipe(map((data: FiComplianceDto) => data)),
    );
  }

  async DeleteFiContactNote(fiId: number, isOdfi: boolean) {
    return lastValueFrom(
      this.httpClient.delete(`api/fi/${fiId}/notes?isOdfi=${isOdfi}`),
    );
  }

  async GetPendingContactsAsync(
    skip: number,
    take: number,
    filters: PendingACHContactApprovalFilter,
  ) {
    return lastValueFrom(
      this.httpClient
        .post(
          `api/contact-registry/contacts/pending-approvals?skip=${skip}&take=${take}`,
          filters,
        )
        .pipe(map((data: any) => data)),
    );
  }

  async UpdatePendingContactStatusAsync(status: string, contactId: number) {
    return lastValueFrom(
      this.httpClient
        .patch(
          `api/contact-registry/contacts/pending-approvals/${contactId}/status`,
          `"${status}"`,
        )
        .pipe(map((data: any) => data)),
    );
  }

  async GetRoutingNumberList(
    searchCriteria: RoutingSearchCriteriaDto,
  ): Promise<any[]> {
    return lastValueFrom(
      this.httpClient
        .post<any[]>(`api/fi/details`, searchCriteria, true)
        .pipe(map((data: any[]) => data)),
    );
  }

  async VerifyContactEmailAlreadyExistAsync(
    fiContactId: number | null,
    email: string,
  ) {
    let apiUrl = `/api/contact-registry/contacts/verify-email?email=${email}`;
    if (fiContactId !== null && fiContactId !== undefined) {
      apiUrl += `&fiContactId=${fiContactId}`;
    }
    return lastValueFrom(
      this.httpClient.get(apiUrl).pipe(map((data: any) => data)),
    );
  }

  async DeleteContactAsync(contactId: number, comment: string) {
    return lastValueFrom(
      this.httpClient.delete(
        `api/contact-registry/contacts/${contactId}/${comment}`,
      ),
    );
  }
}
